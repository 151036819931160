/* eslint jsx-a11y/anchor-is-valid: 0 */
import classnamesBind from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import reactToString from 'react-to-string';
import Link from '@concur/nui-widgets/lib/Link/Link';
import ScreenReaderMessage from '@concur/nui-widgets/lib/ScreenReaderMessage/ScreenReaderMessage';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose, withErrorBoundary } from '@concur/core-ui-shell';
import ErrorIllustration from './_ErrorIllustration';
import * as styles from './AppError-*.css';

export const ERRORS = {
    aspError: 'AspError',
    browser: 'Browser',
    clientError: 'ClientError',
    comingSoon: 'ComingSoon',
    downloadError: 'DownloadError',
    forbidden: 'Forbidden',
    notFound: 'NotFound',
    profileMoveError: 'ProfileMoveError',
    securityBreach: 'SecurityBreach',
    serverError: 'ServerError',
    timeout: 'Timeout',
};

const CSS_BLOCK = 'sapcnqr-app-error';

const Error = ({
    backUrl,
    className,
    classNameMap,
    correlationId,
    customDescription,
    customHeader,
    formatter,
    homeUrl,
    links,
    paragraphs,
    type,
}) => {
    const [announcement, setAnnouncement] = useState('');
    const classnames = classnamesBind.bind(classNameMap);

    const errorType = ERRORS[type] || 'ServerError';

    const headerTranslationId = `CoreUI.errorPage_${errorType}.header`;
    const descriptionTranslationId = `CoreUI.errorPage_${errorType}.description`;

    const classes = classnames(CSS_BLOCK, className);

    const sectionRef = useRef(null);
    const linksRef = useRef(null);

    useEffect(() => {
        const previousTitle = document.title;
        if (Object.keys(formatter.preferences.messages).length > 0) {
            document
                .title = customHeader || formatter.formattedMessage({ id: headerTranslationId })
                    || previousTitle;
        }

        // try to focus on first helpful link
        if (linksRef?.current && linksRef.current?.getElementsByTagName && linksRef.current.getElementsByTagName('a')?.length > 0) {
            linksRef.current.getElementsByTagName('a')[0].focus();
        }

        return () => {
            document.title = previousTitle;
        };
    }, [formatter, customHeader, headerTranslationId]);

    const hasCustomContent = customDescription || paragraphs.length > 0;
    const errorContents = (
        <div className={classnames(`${CSS_BLOCK}__details`)}>
            {customDescription && (
                <>
                    <div className={classnames(`${CSS_BLOCK}__description`)}>
                        {customDescription}
                    </div>
                </>
            )}
            { ' ' }
            {paragraphs.map((paragraph) => (
                <p key={paragraph.text} className={classnames(`${CSS_BLOCK}__description`, paragraph.className)}>
                    {paragraph.text}
                    { ' ' }
                </p>
            ))}
            { ' ' }
            {!hasCustomContent && (
                <div className={classnames(`${CSS_BLOCK}__description`)}>
                    {formatter.formattedMessage({ id: descriptionTranslationId })}
                </div>
            )}
            { ' ' }
            {correlationId && (
                <div className={classnames(`${CSS_BLOCK}__error-id`)}>
                    {formatter.formattedMessage({
                        id: 'CoreUI.error.correlationid',
                        values: { correlationId },
                    })}
                </div>
            )}
        </div>
    );

    const errorContentsAsString = reactToString(errorContents);

    useEffect(() => {
        let announcementTimer;
        if (sectionRef?.current) {
            announcementTimer = setTimeout(() => {
                setAnnouncement(`${document?.title || ''} ${errorContentsAsString} ${formatter.formattedMessage({ id: 'CoreUI.errorPage_Error.helpful_links' })}`);
            }, 1000);
        }
        return () => {
            clearTimeout(announcementTimer);
        };
    }, [errorContentsAsString]);

    return (
        <section className={classes} ref={sectionRef}>
            <div className={classnames(`${CSS_BLOCK}__container`)}>
                <h1 className={classnames(`${CSS_BLOCK}__header`)}>
                    {customHeader || formatter.formattedMessage({ id: headerTranslationId })}
                </h1>
                {
                    errorContents
                }
                {
                    errorContentsAsString?.trim()
                    && (
                        <ScreenReaderMessage isAlert>
                            {announcement}
                        </ScreenReaderMessage>
                    )
                }
                {(backUrl || homeUrl || links.length > 0) && (
                    <>
                        <h2 className={classnames(`${CSS_BLOCK}__helpful-links-header`)}>
                            {formatter.formattedMessage({ id: 'CoreUI.errorPage_Error.helpful_links' })}
                        </h2>
                        <ul className={classnames(`${CSS_BLOCK}__helpful-links`)} ref={linksRef}>
                            {backUrl && (
                                <li className={classnames(`${CSS_BLOCK}__helpful-link-item`)}>
                                    <Link href={backUrl}>
                                        {formatter.formattedMessage({ id: 'CoreUI.errorPage_Error.back' })}
                                    </Link>
                                </li>
                            )}
                            {homeUrl && (
                                <li className={classnames(`${CSS_BLOCK}__helpful-link-item`)}>
                                    <Link href={homeUrl}>
                                        {formatter.formattedMessage({ id: 'CoreUI.errorPage_Error.home' })}
                                    </Link>
                                </li>
                            )}
                            {links.map((link) => (
                                <li className={classnames(`${CSS_BLOCK}__helpful-link-item`)} key={`${link.text}-${link.url}`}>
                                    <Link href={link.url}>
                                        {link.text}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
            <ErrorIllustration />
        </section>
    );
};

Error.propTypes = {
    backUrl: PropTypes.string,
    className: PropTypes.string,
    correlationId: PropTypes.string,
    customDescription: PropTypes.string,
    paragraphs: PropTypes.arrayOf(
        PropTypes.shape({
            className: PropTypes.string,
            text: PropTypes.string.isRequired,
        }),
    ),
    customHeader: PropTypes.string,
    homeUrl: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        }),
    ),
    type: PropTypes.oneOf(Object.keys(ERRORS)),
};

Error.defaultProps = {
    links: [],
    paragraphs: [],
    type: 'serverError',
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
    withErrorBoundary,
)(Error);
