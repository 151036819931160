import React from 'react';
import PropTypes from 'prop-types';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import { withThemeStyles } from '@concur/react-ui-theming';
import classnamesBind from 'classnames/bind';
import { withFormatter } from '@concur/nui-intl-runtime';
import { withErrorBoundary, compose } from '@concur/core-ui-shell';
import Logo from '../Logo/Logo';
import * as styles from './AppMiniHeader-*.css';

const CSS_BLOCK = 'sapcnqr-app-mini-header';

const MiniHeader = ({
    classNameMap,
    formatter,
    isGov,
    isRetiredBrand,
    isLoggedIn,
    logoutUser,
}) => {
    const classnames = classnamesBind.bind(classNameMap);

    const baseClasses = classnames(CSS_BLOCK);

    const homeIconClasses = classnames(
        `${CSS_BLOCK}__home-icon`,
        {
            [`${CSS_BLOCK}__home-icon--gov`]: isGov,
            [`${CSS_BLOCK}__home-icon--retired`]: isRetiredBrand,
        },
    );

    return (
        <div className={baseClasses}>
            <span>
                <Logo
                    classNameMap={classNameMap}
                    cssBlock={CSS_BLOCK}
                    className={homeIconClasses}
                    isGov={isGov}
                    isRetiredBrand={isRetiredBrand}
                />
            </span>
            {isLoggedIn && (
                <Button
                    data-test="app-mini-header-signout-link"
                    onClick={logoutUser}
                    type="link"
                    className={classnames(`${CSS_BLOCK}__button--signout`)}
                >
                    {formatter.formattedMessage({ id: 'CoreUI.signOut' })}
                </Button>
            )}
        </div>
    );
};

MiniHeader.displayName = 'MiniHeader';

MiniHeader.cssBlock = CSS_BLOCK;

MiniHeader.propTypes = {
    isLoggedIn: PropTypes.bool,
    isGov: PropTypes.bool,
    isRetiredBrand: PropTypes.bool,
    logoutUser: PropTypes.func,
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
    withErrorBoundary,
)(MiniHeader);
