/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import classnamesBind from 'classnames/bind';
import Avatar from '@concur/nui-widgets/lib/Avatar/Avatar';
import Icon from '@concur/nui-widgets/lib/Icon/Icon';
import initials from 'initials';
import Spinner from '@concur/nui-widgets/lib/Spinner/Spinner';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import {
    compose, lowerCase, withErrorBoundary,
} from '@concur/core-ui-shell';
import { CONCUR_THEMES_BRANDING_ID } from '@concur/core-ui-shell/lib/constants';
import initialsSupported from '../utils/initialsSupported';
import LanguageDropdown from './_LanguageDropdown';
import Menu from './_Menu';
import MenuItem from './_MenuItem';
import MenuItemToggle from './_MenuItemToggle';
import Navigation from './_Navigation';
import ProductMenu from './_ProductMenu';
import ThemeSwitcher from './_ThemeSwitcher';
import AppHeaderContext from './_AppHeaderContext';
import TranslationFeedback from '../TranslationFeedback/TranslationFeedback';
import UserProfileMenuFiori from '../UserProfileMenu/UserProfileMenuFiori';
import Logo from '../Logo/Logo';
import { getProfileDisplayText } from '../UserProfileMenu/_ProfileIdentifier';
import * as styles from './Shellbar-*.css';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';
import HelpSvgIcon from './_HelpSvgIcon';
import HelpPortalMenuContainer from './_HelpPortalMenuContainer';
import TranslationFeedbackSvgIcon from './_TranslationFeedbackSvgIcon';

const CSS_BLOCK = 'sapcnqr-shellbar';

const getActiveMenuItem = (menuData) => {
    const {
        homeMenuItem,
        applicationMenuItems,
        profileMenuItem,
        adminMenuItem,
        helpMenuItem,
        linksMenuItem,
        testDriveMenuItem,
        subscribeMenuItem,
        supportMenuItem,
    } = menuData;

    // create array of all menu items that could be active
    const topLevelMenuItems = [
        homeMenuItem,
        testDriveMenuItem,
        ...(applicationMenuItems || []),
        adminMenuItem,
        linksMenuItem,
        helpMenuItem,
        profileMenuItem,
        subscribeMenuItem,
        supportMenuItem,
    ];

    const matchingMenuItems = topLevelMenuItems.filter((item) => item && item.isActive);

    return (matchingMenuItems.length > 0 ? matchingMenuItems[0] : homeMenuItem);
};

const Shellbar = (props) => {
    const {
        actingAs,
        brandingId,
        className,
        classNameMap,
        formatter,
        isGov,
        isLoggedIn,
        isRetiredBrand,
        lang,
        logoutUser,
        menuData,
        onEndSession,
        onStartSession,
        showLanguageDropdown,
    } = props;

    const {
        homeMenuItem,
        profileMenuItem,
        helpMenuItem,
        pageMenuItems,
    } = menuData;

    const classnames = classnamesBind.bind(classNameMap);

    const {
        isLocalizationUser,
        isLocalizationModeEnabled,
    } = useContext(AppHeaderContext) || {};
    const { employeeDisplayName } = useContext(AppHeaderContext) || {};

    const secondaryNavRef = React.createRef();
    const [appHeaderWidth, setAppHeaderWidth] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);
    const [profileMenuExpanded, setProfileMenuExpanded] = useState(false);
    const [helpPortalLoaded, setHelpPortalLoaded] = useState(false);

    const classes = classnames(CSS_BLOCK, className);

    const primaryNavClasses = classnames(
        `${CSS_BLOCK}__nav`,
        `${CSS_BLOCK}__nav--primary`,
    );

    const secondaryNavClasses = classnames(
        `${CSS_BLOCK}__nav`,
        `${CSS_BLOCK}__nav--secondary`,
    );

    const homeIconClasses = classnames(
        `${CSS_BLOCK}__home-icon`,
        {
            [`${CSS_BLOCK}__home-icon--gov`]: isGov,
            [`${CSS_BLOCK}__home-icon--retired`]: isRetiredBrand,
        },
    );

    const profileButtonLabel = profileMenuExpanded
        ? formatter.formattedMessage({ id: 'CoreUI.profile.closeProfileMenu' })
        : formatter.formattedMessage({ id: 'CoreUI.profile.openProfileMenu' });

    const { allUsers, companyName, otherUserName } = actingAs;
    const isActingForOthers = !!(allUsers || companyName || otherUserName);

    const generateProfileButton = () => {
        const profileDisplayText = getProfileDisplayText({
            actingAs,
            formatter,
            employeeDisplayName,
            shortNames: true,
        });

        if (isActingForOthers) {
            return (
                <>
                    <Icon
                        ariaHidden
                        className={classnames(`${CSS_BLOCK}__acting-as-icon`)}
                        iconName="user-admin"
                    />
                    <div className={classnames(`${CSS_BLOCK}__profile-description`)} title={profileDisplayText}>
                        {profileDisplayText}
                    </div>
                    <span className={classnames(`${CSS_BLOCK}__screen-reader-only`)}>
                        {profileButtonLabel}
                    </span>
                </>
            );
        }

        if (initialsSupported(lang)) {
            const avatarInitials = typeof employeeDisplayName === 'string'
                ? initials.parse(employeeDisplayName).initials
                : undefined;

            if (avatarInitials) {
                return (
                    <Avatar
                        ariaLabel={profileButtonLabel}
                        accentColor={6}
                        data-test="user-icon-avatar-shellbar"
                        iconName="user"
                        size="xs"
                    >
                        {avatarInitials}
                    </Avatar>
                );
            }
        }

        return (
            <Avatar
                ariaLabel={profileButtonLabel}
                accentColor={6}
                data-test="user-icon-avatar-shellbar"
                iconName="user"
                size="xs"
            />
        );
    };

    const activeMenuItem = getActiveMenuItem(menuData) || {};

    const getWidth = (ref) => ref?.current?.offsetWidth || 0;

    const handleClick = (e, expanded) => setProfileMenuExpanded(expanded);

    const handleClose = () => setProfileMenuExpanded(false);

    const handleStartLoading = () => {
        setShowSpinner(true);
    };

    const handleEndLoading = () => {
        setShowSpinner(false);
    };

    const handleResize = () => {
        setAppHeaderWidth(getWidth(secondaryNavRef));
    };
    // runs only on load - this gets us our default utility and container widths
    useEffect(handleResize, []);

    // runs on every resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const navLogo = (
        <Logo
            classNameMap={classNameMap}
            cssBlock={CSS_BLOCK}
            className={homeIconClasses}
            isGov={isGov}
            isRetiredBrand={isRetiredBrand}
        />
    );

    const profileQuickHelpRef = useRef();
    const profileUserDropdownRef = useRef();

    const profileOverlayProps = {
        onClickOutside: (event) => {
            if (profileQuickHelpRef?.current?.contains?.(event?.target)
                || profileUserDropdownRef?.current?.contains?.(event?.target)) {
                return true;
            }

            return false;
        },
        className: showSpinner
            ? classnames(`${CSS_BLOCK}__overlay-profile--spinner-visible`)
            : undefined,
    };

    return (
        <div className={classes} role="banner">
            {isLoggedIn ? (
                <Navigation
                    ariaLabel={formatter.formattedMessage({ id: 'CoreUI.primaryMenuAriaLabel' })}
                    className={primaryNavClasses}
                >
                    <Menu
                        className={classnames(`${CSS_BLOCK}__menu`, `${CSS_BLOCK}__menu--primary-home`)}
                        id="home"
                    >
                        <MenuItemToggle
                            buttonAriaLabel={activeMenuItem.name}
                            caretClassName={classnames(`${CSS_BLOCK}__branded-product-menu-caret`)}
                            classNameMap={classNameMap}
                            cssBlock={CSS_BLOCK}
                            buttonClassName={classnames(`${CSS_BLOCK}__branded-product-menu-button`)}
                            buttonProps={{
                                'data-test': 'sapcnqr-shellbar-product-menu',
                            }}
                            overlayProps={{
                                popperContentProps: {
                                    className: classnames(`${CSS_BLOCK}__product-menu`),
                                },
                            }}
                            content={navLogo}
                        >
                            <ProductMenu isFioriProductMenu menuData={menuData} />
                        </MenuItemToggle>
                    </Menu>
                    <Menu
                        className={classnames(`${CSS_BLOCK}__menu`, `${CSS_BLOCK}__menu--primary`)}
                        id="primary"
                    >
                        {homeMenuItem && (
                            <MenuItem
                                {...homeMenuItem}
                                classNameMap={classNameMap}
                                cssBlock={CSS_BLOCK}
                                className={classnames(`${CSS_BLOCK}__listitem--home`)}
                                isActive={false} // home menu item is never shown as active
                                key="dashboard"
                            >
                                {navLogo}
                            </MenuItem>
                        )}
                        <MenuItemToggle
                            buttonAriaLabel={activeMenuItem.name}
                            classNameMap={classNameMap}
                            cssBlock={CSS_BLOCK}
                            buttonClassName={classnames(`${CSS_BLOCK}__button--product`)}
                            buttonProps={{
                                'data-test': 'sapcnqr-shellbar-product-menu',
                            }}
                            overlayProps={{
                                popperContentProps: {
                                    className: classnames(`${CSS_BLOCK}__product-menu`),
                                },
                            }}
                            content={activeMenuItem.name}
                        >
                            <ProductMenu isFioriProductMenu menuData={menuData} />
                        </MenuItemToggle>
                    </Menu>
                    <div className={classnames(`${CSS_BLOCK}__right`)}>
                        {brandingId === CONCUR_THEMES_BRANDING_ID && <ThemeSwitcher checked />}
                        {(isLocalizationUser || helpMenuItem || profileMenuItem) && (
                            <Menu
                                className={classnames(`${CSS_BLOCK}__menu`, `${CSS_BLOCK}__menu--utility`)}
                                id="utility"
                                isTopLevelMenu
                            >
                                {isLocalizationUser && (
                                    <MenuItemToggle
                                        buttonAriaLabel={formatter.formattedMessage({ id: 'CoreUI.TranslationTool.header' })}
                                        buttonClassName={classnames(
                                            `${CSS_BLOCK}__button--icon`,
                                            {
                                                [`${CSS_BLOCK}__button--on`]: isLocalizationModeEnabled,
                                            },
                                        )}
                                        classNameMap={classNameMap}
                                        cssBlock={CSS_BLOCK}
                                        content={(
                                            <TranslationFeedbackSvgIcon
                                                className={classnames(`${CSS_BLOCK}__svg-icon`)}
                                            />
                                        )}
                                        hideCaret
                                        iconOnly
                                        popperPlacement="bottom-end"
                                    >
                                        <TranslationFeedback
                                            isEnabled={isLocalizationModeEnabled}
                                        />
                                    </MenuItemToggle>
                                )}
                                <HelpPortalMenuContainer
                                    cssBlock={CSS_BLOCK}
                                    menuData={menuData}
                                    onHelpPortalLoad={setHelpPortalLoaded}
                                />
                                {helpMenuItem && !helpPortalLoaded && (
                                    <MenuItemToggle
                                        buttonAriaLabel={helpMenuItem.name}
                                        buttonProps={{ 'data-test': helpMenuItem.id ? `menu__listitem-${lowerCase(helpMenuItem.id)}` : null }}
                                        classNameMap={classNameMap}
                                        cssBlock={CSS_BLOCK}
                                        content={(
                                            <HelpSvgIcon
                                                className={classnames(`${CSS_BLOCK}__svg-icon`)}
                                            />
                                        )}
                                        hideCaret
                                        iconOnly
                                        popperPlacement="bottom-end"
                                    >
                                        <Menu
                                            className={classnames(`${CSS_BLOCK}__submenu`, `${CSS_BLOCK}__submenu--toggle`)}
                                            id={helpMenuItem.id}
                                        >
                                            {helpMenuItem.subItems.map((item, index) => (
                                                <MenuItem
                                                    {...item}
                                                    classNameMap={classNameMap}
                                                    cssBlock={CSS_BLOCK}
                                                    key={`help${index}`}
                                                />
                                            ))}
                                        </Menu>
                                    </MenuItemToggle>
                                )}
                                {profileMenuItem && (
                                    <>
                                        <MenuItemToggle
                                            buttonClassName={isActingForOthers ? classnames(`${CSS_BLOCK}__button--acting-as`) : undefined}
                                            buttonProps={{ 'data-test': profileMenuItem.id ? `menu-${lowerCase(profileMenuItem.id)}` : null }}
                                            overlayProps={profileOverlayProps}
                                            classNameMap={classNameMap}
                                            cssBlock={CSS_BLOCK}
                                            content={generateProfileButton()}
                                            onClick={handleClick}
                                            onClose={handleClose}
                                            hideCaret
                                            iconOnly={!isActingForOthers}
                                            popperPlacement="bottom-end"
                                        >
                                            <UserProfileMenuFiori
                                                actingAs={actingAs}
                                                isGov={isGov}
                                                logoutUser={logoutUser}
                                                onEndLoading={handleEndLoading}
                                                onEndSession={onEndSession}
                                                onStartLoading={handleStartLoading}
                                                onStartSession={onStartSession}
                                                profileUrl={profileMenuItem.url}
                                                errorComp={(errorId) => (
                                                    <FallbackComponent
                                                        componentCssBlock={UserProfileMenu.cssBlock}
                                                        correlationId={errorId}
                                                    />
                                                )}
                                                actingAsQuickHelpRef={profileQuickHelpRef}
                                                actingAsUserDropdownRef={profileUserDropdownRef}
                                            />
                                        </MenuItemToggle>
                                        <Spinner
                                            message={formatter.formattedMessage({ id: 'CoreUI.switchingActingAsView' })}
                                            type="fullScreen"
                                            visible={showSpinner}
                                        />
                                    </>
                                )}
                            </Menu>
                        )}
                    </div>
                </Navigation>
            ) : (
                <span className={classnames(`${CSS_BLOCK}__nav`, {
                    [`${CSS_BLOCK}__nav--signin`]: !isLoggedIn,
                })}
                >
                    <span className={classnames(`${CSS_BLOCK}__brand`)}>
                        <Logo
                            cssBlock={CSS_BLOCK}
                            classNameMap={classNameMap}
                            className={homeIconClasses}
                            isGov={isGov}
                            isRetiredBrand={isRetiredBrand}
                        />
                    </span>
                    {showLanguageDropdown && (
                        <LanguageDropdown
                            classNameMap={classNameMap}
                            cssBlock={CSS_BLOCK}
                        />
                    )}
                </span>
            )}
            {isLoggedIn && pageMenuItems && pageMenuItems.length > 0 && (
                <Navigation
                    ariaLabel={formatter.formattedMessage({ id: 'CoreUI.secondaryMenuAriaLabel' })}
                    className={secondaryNavClasses}
                    ref={secondaryNavRef}
                >
                    <Menu
                        className={classnames(`${CSS_BLOCK}__menu`)}
                        collapsible
                        appHeaderWidth={appHeaderWidth}
                        id="secondary"
                    >
                        {pageMenuItems.map((item, index) => (
                            (item.subItems) ? (
                                <MenuItemToggle
                                    buttonAriaLabel={item.name}
                                    classNameMap={classNameMap}
                                    content={item.name}
                                    cssBlock={CSS_BLOCK}
                                    key={`pageSubMenu${index}`}
                                    popperPlacement="bottom-start"
                                >
                                    <Menu
                                        className={classnames(`${CSS_BLOCK}__submenu`, `${CSS_BLOCK}__submenu--toggle`)}
                                    >
                                        {item.subItems.map((subItem, subIndex) => (
                                            <MenuItem
                                                {...subItem}
                                                classNameMap={classNameMap}
                                                cssBlock={CSS_BLOCK}
                                                key={`secondary${subIndex}`}
                                                hasPopover
                                            />
                                        ))}
                                    </Menu>
                                </MenuItemToggle>
                            ) : (
                                <MenuItem
                                    {...item}
                                    classNameMap={classNameMap}
                                    cssBlock={CSS_BLOCK}
                                    key={`secondary${index}`}
                                />
                            )
                        ))}
                        <MenuItem
                            classNameMap={classNameMap}
                            cssBlock={CSS_BLOCK}
                            isOverflow
                            key="overflowSecondary"
                        />
                    </Menu>
                </Navigation>
            )}
        </div>
    );
};

Shellbar.displayName = 'Shellbar';

Shellbar.cssBlock = CSS_BLOCK;

Shellbar.defaultProps = {
    actingAs: {},
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
    withErrorBoundary,
)(Shellbar);
