import React from 'react';

const TranslationFeedbackSvgIcon = (props) => (
    <svg
        {...props}
        aria-hidden
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <path d="M509.433 449q4 9 .5 18t-13.5 12q-9 4-18-.5t-13-13.5l-21-53h-102l-21 53q-4 9-12.5 13.5t-17.5.5q-9-3-13.5-12t-.5-18l93-232q10-14 26-14 7 0 12.5 3t7.5 11zm-83-84l-33-79-32 79h65zm-186-7l-2-1-3-3-5.5-5.5-8.5-8.5q-7-8-15.5-16t-16.5-17l-100 100q-8 7-17 7t-16-7-7-16 7-16l102-103q-7-8-13.5-16t-14.5-16q-10-14-19.5-26t-14.5-23q-4-11-.5-19.5t11.5-13.5q9-3 18-1t12 11q4 7 10.5 17.5t17.5 24.5q5 5 12 11.5t12 13.5q5-9 11.5-18.5t13.5-20.5q9-13 17-26t13-26q8-12 10-20.5t2-13.5h-233q-10 0-16.5-6.5T.433 86t6.5-17 16.5-6h140V40q0-23 23-23 11 0 17.5 6t6.5 17v23h139q23 0 23 23 0 11-6 17.5t-17 6.5h-46q0 14-6 27.5t-11 27.5q-14 30-32 56-9 14-17.5 26t-15.5 21l-2 2q11 12 19 21t16 16q6 8 14 14 2 2 4 5 8 7 8 16t-8 16q-7 8-16 8t-16-8z" />
    </svg>
);

export default TranslationFeedbackSvgIcon;
