import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classnamesBind from 'classnames/bind';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import Modal from '@concur/nui-widgets/lib/Modal/Modal';
import Spinner from '@concur/nui-widgets/lib/Spinner/Spinner';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose, withLogger } from '@concur/core-ui-shell';
import { ENDPOINT_URLS } from '../constants';
import InlineError from '../FallbackComponent/_InlineError';
import withLoggerData from '../utils/withLoggerData';

const LastLogin = (props) => {
    const {
        classNameMap,
        cssBlock,
        formatter,
        lastLogin,
        lastSigninDateString,
        logger,
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [correlationId, setCorrelationId] = useState();
    const [lastLoginModal, setLastLoginModal] = useState(false);
    const [lastLoginContent, setLastLoginContent] = useState();

    const promptLoginHistory = () => {
        const tmpCorrelationId = uuidv4();
        setIsLoading(true);
        setLastLoginModal(true);

        axios.get(ENDPOINT_URLS.LAST_LOGIN, { headers: { 'concur-correlationid': tmpCorrelationId } })
            .then((response) => {
                setIsLoading(false);
                if (!response.data) {
                    logger.error('Empty last login response', { correlationId: tmpCorrelationId });
                    setError(true);
                    setCorrelationId(tmpCorrelationId);
                } else {
                    setLastLoginContent(response.data);
                }
            }).catch((err) => {
                logger.error(`Fetching last login: ${err.message}`, { correlationId: tmpCorrelationId });
                setIsLoading(false);
                setError(true);
                setCorrelationId(tmpCorrelationId);
            });
    };

    // TODO: Once legacy only sends lastSigninDateString we can remove the deprecated prop
    const lastLoginDisplay = lastSigninDateString
        ? formatter.formattedMessage({ id: 'CoreUI.lastSignIn.info', values: { 1: lastSigninDateString } })
        : lastLogin;

    return (
        <>
            <Button
                className={classnames(`${cssBlock}__button`)}
                data-test="app-footer-last-login-button"
                onClick={promptLoginHistory}
                type="link"
            >
                {lastLoginDisplay}
            </Button>
            <Modal
                onHide={() => setLastLoginModal(false)}
                show={lastLoginModal}
            >
                <Modal.Header closeButton closeLabel={formatter.formattedMessage({ id: 'CoreUI.close' })}>
                    <Modal.Title>{formatter.formattedMessage({ id: 'CoreUI.loginHistory' })}</Modal.Title>
                </Modal.Header>
                {isLoading && (
                    <Modal.Body>
                        <Spinner
                            maskColor="white"
                            message={formatter.formattedMessage({ id: 'CoreUI.loading' })}
                            size="md"
                            type="block"
                            visible
                        />
                    </Modal.Body>
                )}
                {!isLoading && error && (
                    <Modal.Body>
                        <InlineError correlationId={correlationId} />
                    </Modal.Body>
                )}
                {!isLoading && !error && (
                    <Modal.Body
                        dangerouslySetInnerHTML={{ __html: lastLoginContent }}
                    />
                )}
                <Modal.Footer><Button onClick={() => setLastLoginModal(false)}>{formatter.formattedMessage({ id: 'CoreUI.close' })}</Button></Modal.Footer>
            </Modal>
        </>
    );
};

LastLogin.displayName = 'LastLogin';

LastLogin.propTypes = {
    classNameMap: PropTypes.object,
    cssBlock: PropTypes.string,
    lastLogin: PropTypes.string, // Deprecated -- use lastSigninDateString instead
    lastSigninDateString: PropTypes.string,
};

export default compose(
    withLoggerData,
    withLogger,
    withFormatter,
)(LastLogin);
