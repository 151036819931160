/* eslint-disable jsx-a11y/anchor-is-valid */
import ButtonDropdown from '@concur/nui-widgets/lib/Buttons/ButtonDropdown';
import classnamesBind from 'classnames/bind';
import Icon from '@concur/nui-widgets/lib/Icon/Icon';
import { languageDetails, withFormatter } from '@concur/nui-intl-runtime';
import MenuGroup from '@concur/nui-widgets/lib/Buttons/MenuGroup';
import MenuItem from '@concur/nui-widgets/lib/Buttons/MenuItem';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import AppHeaderContext from './_AppHeaderContext';

const LanguageDropdown = (props) => {
    const {
        classNameMap,
        cssBlock,
        formatter,
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const {
        isMayFourth,
    } = useContext(AppHeaderContext) || {};

    const getLangHref = (lang) => {
        const query = queryString.parse(window.location.search);

        const queryWithLang = {
            ...query,
            lang,
        };

        return `${window.location.pathname}?${queryString.stringify(queryWithLang)}`;
    };

    // Generates the MenuItem list for Tier 1 Language dropdown on nui-signin
    const languageButtonMenuItems = () => Object.keys(languageDetails)
        .filter((lang) => languageDetails[lang].tier === 1)
        .map((lang) => (
            <MenuItem
                key={lang}
                href={getLangHref(lang)}
            >
                {languageDetails[lang].name}
            </MenuItem>
        ));

    return (
        <ButtonDropdown
            buttonClassName={classnames(`${cssBlock}__button`)}
            dropdownMaxHeight={308}
            hideTextLabel
            id="langMenuItem"
            pullRight
            textLabel={formatter.formattedMessage({ id: 'CoreUI.languageMenuAriaLabel' })}
            title={(
                <>
                    <Icon ariaHidden iconName={isMayFourth ? 'not-a-moon' : 'globe'} />
                    {formatter?.preferences?.lang
                        && languageDetails[formatter?.preferences?.lang].name}
                </>
            )}
            type="link"
        >
            <MenuGroup>
                {languageButtonMenuItems()}
            </MenuGroup>
        </ButtonDropdown>
    );
};

LanguageDropdown.displayName = 'LanguageDropdown';

LanguageDropdown.propTypes = {
    cssBlock: PropTypes.string,
};

export default withFormatter(LanguageDropdown);
