/* eslint-disable import/first */
// Must be the first import
if (process.env.NODE_ENV === 'development') {
    // Must use require here as import statements are only allowed
    // to exist at the top of a file.
    // eslint-disable-next-line global-require
    require('preact/debug');
}
import { exportComponents } from '@concur/react-interop';
import AppBanner from '../src/AppBanner/AppBanner';
import AppError from '../src/AppError/AppError';
import AppFooter from '../src/AppFooter/AppFooter';
import AppHeader from '../src/AppHeader/AppHeader';
import AppMiniHeader from '../src/AppMiniHeader/AppMiniHeader';
import AppIdleTimer from '../src/AppIdleTimer/AppIdleTimer';
import { version } from '../package.json';

// Support finding Webpack chunks on CDN
const SCRIPT_IDENTIFIER = 'ui-floorplans';

const scriptElements = document.getElementsByTagName('script');
for (let i = scriptElements.length - 1; i >= 0; i -= 1) {
    const scriptElement = scriptElements[i];
    if (scriptElement.src && scriptElement.src.indexOf(SCRIPT_IDENTIFIER) >= 0) {
        const publicPath = scriptElement.src.substr(0, scriptElement.src.lastIndexOf('/') + 1);
        // eslint-disable-next-line camelcase
        __webpack_public_path__ = publicPath;
        break;
    }
}

const components = {
    ...exportComponents({
        AppBanner,
        AppError,
        AppFooter,
        AppHeader,
        AppMiniHeader,
        AppIdleTimer,
    }),
};

// Expose components on the cnqr.coreui namespace
global.cnqr = global.cnqr || {};
global.cnqr.coreui = {
    ...components,
    ...global.cnqr.coreui,
    uiFloorplansVersion: version,
};
