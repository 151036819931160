import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import shortId from '@concur/nui-widgets/lib/utils/shortId';

const SapConcurLogo = (props) => {
    const {
        classNameMap,
        fill1ClassName,
        fill2ClassName,
        ...otherProps
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const idSuffix = shortId.generate();

    return (
        <svg
            id={`SAPConcur_logo_${idSuffix}`}
            data-test="SAPConcur_logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 240.65 43.06"
            role="img"
            {...otherProps}
        >
            <title>SAP Concur</title>
            <path
                className={classnames(fill1ClassName)}
                d="M37.21,65.27l4-2.36A7.08,7.08,0,0,0,47.64,67c2.42,0,4.17-.91,4.17-2.75s-1.7-2.58-4.84-3.46c-4.26-1.2-8.72-2.29-8.72-7.3,0-4.46,3.49-7.14,8.62-7.14a10.75,10.75,0,0,1,9.5,4.91L52.72,54a7.12,7.12,0,0,0-5.88-3.39c-2.23,0-3.46,1-3.46,2.39,0,1.91,1.78,2.58,4.91,3.46,4.2,1.13,8.66,2.46,8.66,7.4,0,3.84-2.9,7.27-9.56,7.27C42.22,71.14,39,68.69,37.21,65.27Zm20.7,5.43,8.47-24h5l8.47,24H74.52l-1.78-5.33H64.6l-1.85,5.33Zm8-9.14h5.63l-2.75-8.14H68.7Zm16.6,9.14V46.79h8.7c5.1,0,9.75,1.36,9.75,7.66s-5,7.75-9.82,7.75H87.73v8.5Zm5.24-12.57h3.58c3.26,0,4.49-1.26,4.49-3.55s-1.19-3.49-4.49-3.49H87.73Zm23.54.55c0-8.3,4.81-12.37,10.85-12.37s8.65,3.23,9.69,7.53l-5,1.45c-.77-2.75-1.94-4.62-4.75-4.62-3.62,0-5.56,3.07-5.56,8s2,8.2,5.62,8.2c2.81,0,4.2-1.78,5-5l4.95,1.1c-1.16,4.81-4,8.17-10,8.17C115.89,71.11,111.27,67.11,111.27,58.68ZM134,62.06c0-6.75,4.62-9,8.82-9s8.78,2.23,8.78,9S147,71,142.79,71,134,68.75,134,62.06Zm12.4,0c0-2.88-1-5.2-3.59-5.2s-3.62,2.1-3.62,5.2c0,2.87,1,5.26,3.62,5.26C145.24,67.36,146.38,65.23,146.38,62.1Zm7.74,8.56V53.44h5.2v2.71a7.37,7.37,0,0,1,5.94-3.07c2.91,0,5,1.46,5,5V70.66h-5.16V59.74c0-1.55-.58-2.36-2-2.36a5.81,5.81,0,0,0-3.75,1.71V70.66Zm18.67-8.59c0-6.88,4.88-9,8.69-9,4.69,0,6.49,2.49,7.49,5.27L184.6,59.8c-.68-2.2-1.68-2.91-3.2-2.91-2.1,0-3.42,1.78-3.42,5.2,0,3.06,1,5.26,3.36,5.26,1.39,0,2.65-.74,3.39-3.07l4.3,1.49A7.77,7.77,0,0,1,181.24,71C177.2,71,172.78,68.75,172.78,62.06ZM191.68,66V53.44h5.2v11c0,1.55.58,2.39,2,2.39a5.84,5.84,0,0,0,3.74-1.74V53.44h5.2V70.66h-5.2V68a7.44,7.44,0,0,1-5.94,3C193.78,71,191.68,69.56,191.68,66Zm19.9,4.65V53.44h5.2v2.88h0a6.51,6.51,0,0,1,5.81-3.23h.16l-.06,4.62c-.19,0-1-.1-1.36-.1A5.75,5.75,0,0,0,216.78,60V70.66Z"
                transform="translate(-37.21 -37.21)"
            />
            <path
                className={classnames(fill2ClassName)}
                d="M255.3,65.39A6.58,6.58,0,1,1,260,54.16l3.41-3.41a11.41,11.41,0,1,0,0,16.12L260,63.46a6.56,6.56,0,0,1-4.65,1.93"
                transform="translate(-37.21 -37.21)"
            />
            <path
                className={classnames(fill2ClassName)}
                d="M264.89,55.93a2.88,2.88,0,1,0,2.88,2.88,2.88,2.88,0,0,0-2.88-2.88"
                transform="translate(-37.21 -37.21)"
            />
            <path
                className={classnames(fill2ClassName)}
                d="M234.8,80.27h43.06V37.21H234.8Zm4.82-4.82V42H273V75.45Z"
                transform="translate(-37.21 -37.21)"
            />
        </svg>
    );
};

SapConcurLogo.displayName = 'SapConcurLogo';

SapConcurLogo.propTypes = {
    fill1ClassName: PropTypes.string,
    fill2ClassName: PropTypes.string,
};

export default SapConcurLogo;
