import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { withFormatter } from '@concur/nui-intl-runtime';
import {
    compose, withLogger, withStylesLoaded,
} from '@concur/core-ui-shell';
import { isFiori } from '@concur/ui-theming';
import GatewayHeader from './_GatewayHeader';
import Shellbar from './_Shellbar';
import {
    SERVICE_DESCRIPTION_GUIDE_ID,
    SERVICE_DESCRIPTION_GUIDE_URL,
    // UICOMP-5716: removing this until a better rollout plan is established
    // TRANSLATION_TOOL_URL_IDENTIFIER,
} from '../constants';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';
import InlineError from '../FallbackComponent/_InlineError';
import withNuiIntl from '../utils/withNuiIntl';
import withStylesPlaceholder from '../utils/withStylesPlaceholder';
import withLoggerData from '../utils/withLoggerData';
import { isLocalizationModeEnabled } from '../utils/localizationMode';
import AppHeaderContext from './_AppHeaderContext';

const getProfileMenuItem = ({ formatter, isLoggedIn }) => (isLoggedIn ? {
    name: formatter.formattedMessage({ id: 'CoreUI.profile.profile' }),
    id: 'profile',
    url: '#', // too many variables to try and determine the proper profile settings page here
} : undefined
);

const getHelpMenuItem = ({ formatter, menuData, isGov }) => {
    const adjustedHelpMenuItem = menuData?.helpMenuItem || {
        name: formatter.formattedMessage({ id: 'CoreUI.help' }),
        id: 'help',
        url: '/',
    };

    // due to how language bundles load, we have have gotten the menu item, but
    // not gotten the text so let's go back and update that
    adjustedHelpMenuItem.name = formatter.formattedMessage({ id: 'CoreUI.help' });

    const helpSubMenuItems = adjustedHelpMenuItem.subItems || [];

    if (!isGov) {
        // add Service Description Guide if not present for all but gov
        const sdgMenuItem = helpSubMenuItems.find(
            (item) => item.id === SERVICE_DESCRIPTION_GUIDE_ID,
        );
        // due to how language bundles load, we have have gotten the menu item, but
        // not gotten the text so let's go back and update that
        if (sdgMenuItem) {
            sdgMenuItem.name = formatter.formattedMessage({ id: 'CoreUI.serviceDescriptionGuide' });
        } else {
            helpSubMenuItems.push({
                name: formatter.formattedMessage({ id: 'CoreUI.serviceDescriptionGuide' }),
                id: SERVICE_DESCRIPTION_GUIDE_ID,
                url: SERVICE_DESCRIPTION_GUIDE_URL,
                target: '_blank',
            });
        }
    }

    const adjustHelpSubMenuItems = helpSubMenuItems.map((item) => (
        {
            ...item,
            anchorProps: {
                // format requested by analytics team
                'data-analytics-json': `{"applicationName":"helplinks","elementPath":"${item?.id}","action":"click","elementType":"link"}`,
            },
        }
    ));

    adjustedHelpMenuItem.subItems = adjustHelpSubMenuItems;

    return adjustedHelpMenuItem;
};

const getMinimalMenuData = ({
    formatter, isLoggedIn, logger, isGov,
}) => {
    const correlationId = uuidv4();
    if (isLoggedIn) logger.error('Menu data is missing', { correlationId });

    return {
        helpMenuItem: getHelpMenuItem({ formatter, isGov }),
        homeMenuItem: {
            name: formatter.formattedMessage({ id: 'CoreUI.home' }),
            id: 'home',
            url: isLoggedIn ? '/home.asp' : undefined,
        },
        profileMenuItem: getProfileMenuItem({ formatter, isLoggedIn }),
        // mark this so we know this is "fake" menu data
        noMenuDataFallback: isLoggedIn ? (
            <InlineError correlationId={correlationId} />
        ) : undefined,
    };
};

const isMayFourth = () => {
    // May Fourth Easter Egg
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    // currentMonth is 0 indexed (Jan === 0)
    return currentMonth === 4 && currentDay === 4;
};

const getEmployeeDisplayName = ({
    employeeName,
    fullName,
    formatter,
}) => {
    // backwards compatibility
    if (!employeeName) {
        return fullName;
    }

    return formatter.formattedMessage({
        id: employeeName.preferredName
            ? 'CoreUI.employeeDisplayName.withPreferred'
            : 'CoreUI.employeeDisplayName.withoutPreferred',
        values: {
            FN: employeeName.firstName,
            LN: employeeName.lastName,
            MN: employeeName.middleName,
            MI: employeeName.middleName?.charAt(0),
            PN: employeeName.preferredName,
        },
    });
};

// in lieu of passing in user permissions, we can scan the help menu items
// for the existence of the changeLocalizationMode function call in the url
// UICOMP-5716: removing this until a better rollout plan is established
// const isLocalizationUser = (helpMenuItem) => helpMenuItem?.subItems?.some(
//     (item) => item.url?.indexOf(TRANSLATION_TOOL_URL_IDENTIFIER) >= 0,
// );

const AppHeader = (props) => {
    const {
        employeeName,
        fullName,
        menuData,
        ...otherProps
    } = props;

    // This is a patch for nui apps that rely on Menu.cshtml urls like
    // eslint-disable-next-line
    // javascript:if (CNQR && CNQR.currentPage && (CNQR.currentPage === 'processor') && CNQR.processor && CNQR.processor.MenuLinkClicked) {CNQR.processor.MenuLinkClicked()} else {document.location.href='?RptListFilter=processor'}
    // which Error if CNQR is not defined
    // TODO: It'd be better to do these checks here:
    // https://github.concur.com/t1/travel/blob/master/Outtask%20Web%20Server/SharedMasterPages/Gateway/Menu.cshtml
    if (typeof CNQR === 'undefined') {
        window.CNQR = {};
    }

    if (menuData && !menuData.profileMenuItem) {
        menuData.profileMenuItem = getProfileMenuItem(props);
    }

    if (menuData) {
        menuData.helpMenuItem = getHelpMenuItem(props);
    }

    const adjustedProps = {
        ...otherProps,
        menuData: menuData || getMinimalMenuData(props),
    };

    const HeaderComponent = isFiori(props.brandingId, props.themeName) ? Shellbar : GatewayHeader;
    const contextValue = {
        isFiori: isFiori(props.brandingId, props.themeName),
        isMayFourth: isMayFourth(),
        // UICOMP-5716: removing this (and hardcoding value to false)
        // until a better rollout plan is established
        // isLocalizationUser: isLocalizationUser(menuData?.helpMenuItem),
        isLocalizationUser: false,
        isLocalizationModeEnabled: isLocalizationModeEnabled(),
        employeeDisplayName: getEmployeeDisplayName(props),
    };

    return (
        <AppHeaderContext.Provider value={contextValue}>
            <HeaderComponent
                {...adjustedProps}
                errorComp={(errorId) => (
                    <FallbackComponent
                        componentCssBlock={HeaderComponent.cssBlock}
                        correlationId={errorId}
                    />
                )}
            />
        </AppHeaderContext.Provider>
    );
};

AppHeader.displayName = 'AppHeader';

AppHeader.propTypes = {
    ...GatewayHeader.propTypes,
};

export default compose(
    withStylesPlaceholder,
    withStylesLoaded,
    withNuiIntl,
    withFormatter,
    withLoggerData,
    withLogger,
)(AppHeader);
