export const ACTING_AS_OPTIONS = {
    MYSELF: 'myself',
    DELEGATE: 'delegate',
    PROXY: 'proxy',
    SELF_ASSIGN: 'self',
    GOV_ALL_USERS: 'tavsall',
};

export const ENDPOINT_URLS = {
    ACTING_AS_LIST: '/ui/shared/proxylookup/List',
    ACTING_AS_END_SESSION: '/ui/shared/uipermissions/reset',
    ACTING_AS_START_SESSION: '/ui/shared/uipermissions/switch',
    LAST_LOGIN: '/Net2/GetPrevIps.cshtml',
};

export const ASK_QUESTION_CONTACT_EMAIL = 'freetrials@concur.com';
export const ASK_QUESTION_CONTACT_PHONE_NUMBER = '1-888-811-2036';

export const SERVICE_DESCRIPTION_GUIDE_URL = 'https://www.concurtraining.com/customers/tech_pubs/SDGs/_SDGs.htm';
export const SERVICE_DESCRIPTION_GUIDE_ID = 'Service-Description-Guide';

export const BANNER_TYPE = {
    none: 0,
    ieBefore11: 1,
    ie11: 2,
};

export const COOKIE_PREF_LEVEL = {
    none: 0,
    functional: 1,
    advertising: 2,
};

export const ERROR_CATEGORY = 'ui-floorplans';

export const TRANSLATION_TOOL_URL_IDENTIFIER = 'changeLocalizationMode';
