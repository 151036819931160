import React from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import Switch from '@concur/nui-widgets/lib/Switch/Switch';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose } from '@concur/core-ui-shell';
import { setLocalizationMode } from '../utils/localizationMode';
import * as styles from './TranslationFeedback-*.css';

const CSS_BLOCK = 'sapcnqr-translation-feedback';

const TranslationFeedback = (props) => {
    const {
        className,
        classNameMap,
        formatter,
        isEnabled,
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const classes = classnames(
        CSS_BLOCK,
        className,
    );

    const handleChange = (e, checked) => {
        setLocalizationMode(checked);
    };

    return (
        <div className={classes}>
            <Switch
                checked={isEnabled}
                className={classnames(`${CSS_BLOCK}__switch`)}
                label={formatter.formattedMessage({ id: 'CoreUI.TranslationTool.header' })}
                labelProps={{
                    className: classnames(`${CSS_BLOCK}__label`),
                }}
                onChange={handleChange}
                size="md"
            />
        </div>
    );
};

TranslationFeedback.propTypes = {
    className: PropTypes.string,
    isEnabled: PropTypes.bool,
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
)(TranslationFeedback);
