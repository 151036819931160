import {
    compose,
    useWindowDispatchOnLoad,
    useWindowEventCallback,
} from '@concur/core-ui-shell';
import withModularCSS from '@concur/core-ui-shell/lib/hoc/withModularCSS';
import { withFormatter } from '@concur/nui-intl-runtime';
import React, { useState } from 'react';
import * as styles from './AppHeader-*.css';

const CSS_BLOCK = 'sapcnqr-app-header';

const HelpPortalMenuContainer = ({
    modularClassnames = (...args) => (args),
    menuData,
    onHelpPortalLoad,
}) => {
    const [helpPortalLoaded, setHelpPortalLoaded] = useState(false);
    const menuContainerRef = useWindowDispatchOnLoad('ui-floorplans-loaded');
    const menuRef = useWindowDispatchOnLoad('help-portal-menu-container-added', menuData);

    const handlePortalLoad = () => {
        setHelpPortalLoaded(true);
        onHelpPortalLoad?.(true);
    };

    useWindowEventCallback('help-portal-loaded', handlePortalLoad);

    return (
        <li
            aria-hidden={!helpPortalLoaded}
            ref={menuContainerRef}
            className={modularClassnames(`${CSS_BLOCK}__listitem`)}
        >
            {helpPortalLoaded ? (
                <span ref={menuRef} id="help-portal-menu" />
            ) : null}
        </li>
    );
};

HelpPortalMenuContainer.displayName = 'HelpPortalMenuContainer';

export default compose(
    withModularCSS(styles),
    withFormatter,
)(HelpPortalMenuContainer);
