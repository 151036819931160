import React from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import ButtonToolbar from '@concur/nui-widgets/lib/Buttons/ButtonToolbar';
import Link from '@concur/nui-widgets/lib/Link/Link';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { withErrorBoundary, compose } from '@concur/core-ui-shell';
import ProfileIdentifier from './_ProfileIdentifier';
import ActingAs from './_ActingAs';
import * as styles from './UserProfileMenu-*.css';

const CSS_BLOCK = 'sapcnqr-user-profile-menu';

const UserProfileMenu = ({
    actingAs,
    classNameMap,
    formatter,
    isGov,
    logoutUser,
    onClose,
    onEndLoading,
    onEndSession,
    onStartLoading,
    onStartSession,
    profileUrl,
    actingAsQuickHelpRef,
    actingAsUserDropdownRef,
}) => {
    const classnames = classnamesBind.bind(classNameMap);

    const { allUsers, companyName, otherUserName } = actingAs;
    const isActingForOthers = !!(allUsers || companyName || otherUserName);

    return (
        <div
            className={classnames(CSS_BLOCK)}
            data-test="user-profile-menu"
        >
            <ProfileIdentifier
                actingAs={actingAs}
                classNameMap={classNameMap}
                cssBlock={CSS_BLOCK}
            />

            <ButtonToolbar className={classnames(`${CSS_BLOCK}__toolbar`)} muted>
                <ButtonToolbar.Center>
                    <Link
                        data-test="user-profile-menu-profile-link"
                        disabled={profileUrl === '#'}
                        href={profileUrl !== '#' ? profileUrl : undefined}
                        matchButtonHeight
                    >
                        {formatter.formattedMessage({ id: 'CoreUI.profile.profileSettings' })}
                    </Link>
                    <span className={classnames(`${CSS_BLOCK}__pipe`)} />
                    <Button
                        data-test="user-profile-menu-signout-link"
                        onClick={logoutUser}
                        type="link"
                    >
                        {formatter.formattedMessage({ id: 'CoreUI.signOut' })}
                    </Button>
                </ButtonToolbar.Center>
            </ButtonToolbar>

            <ActingAs
                isActingForOthers={isActingForOthers}
                isGov={isGov}
                onClose={onClose}
                onEndLoading={onEndLoading}
                onEndSession={onEndSession}
                onStartLoading={onStartLoading}
                onStartSession={onStartSession}
                options={actingAs.options}
                quickHelpRef={actingAsQuickHelpRef}
                userDropdownRef={actingAsUserDropdownRef}
            />
        </div>
    );
};

UserProfileMenu.displayName = 'UserProfileMenu';

UserProfileMenu.cssBlock = CSS_BLOCK;

UserProfileMenu.propTypes = {
    actingAs: PropTypes.shape({
        allUsers: PropTypes.bool,
        companyName: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
        otherUserName: PropTypes.string,
    }),
    isGov: PropTypes.bool,
    logoutUser: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onEndSession: PropTypes.func,
    onEndLoading: PropTypes.func,
    onStartLoading: PropTypes.func,
    onStartSession: PropTypes.func,
    profileUrl: PropTypes.string,
    actingAsQuickHelpRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    actingAsUserDropdownRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

UserProfileMenu.defaultProps = {
    actingAs: {},
    profileUrl: '#',
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
    withErrorBoundary,
)(UserProfileMenu);
