import React from 'react';
import { compose, withStylesLoaded } from '@concur/core-ui-shell';
import withNuiIntl from '../utils/withNuiIntl';
import withStylesPlaceholder from '../utils/withStylesPlaceholder';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';
import Banner from './_Banner';

// this wrapper component is necessary to wrap the component
// in a ThemeProvider (handled in withStylesLoaded)
const AppBanner = (props) => (
    <Banner
        {...props}
        errorComp={(errorId) => (
            <FallbackComponent
                componentCssBlock={Banner.cssBlock}
                correlationId={errorId}
                useFullWidth={props?.useFullWidth}
                useSmallViewportBreakpoints={props?.useSmallViewportBreakpoints}
            />
        )}
    />
);

AppBanner.displayName = 'AppBanner';

AppBanner.propTypes = {
    ...Banner.propTypes,
};

export default compose(
    withStylesPlaceholder,
    withStylesLoaded,
    withNuiIntl,
)(AppBanner);
