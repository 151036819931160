import React from 'react';
import { compose, withStylesLoaded } from '@concur/core-ui-shell';
import withNuiIntl from '../utils/withNuiIntl';
import withStylesPlaceholder from '../utils/withStylesPlaceholder';
import Footer from './_Footer';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';

// this wrapper component is necessary to wrap the component
// in a ThemeProvider (handled in withStylesLoaded)
const AppFooter = (props) => (
    <Footer
        {...props}
        errorComp={(errorId) => (
            <FallbackComponent
                componentCssBlock={Footer.cssBlock}
                correlationId={errorId}
            />
        )}
    />
);

AppFooter.displayName = 'AppFooter';

AppFooter.propTypes = {
    ...Footer.propTypes,
};

export default compose(
    withStylesPlaceholder,
    withStylesLoaded,
    withNuiIntl,
)(AppFooter);
