import Badge from '@concur/nui-widgets/lib/Badge/Badge';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactIdleTimer from 'react-idle-timer';
import Modal from '@concur/nui-widgets/lib/Modal/Modal';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import {
    compose, withErrorBoundary, withLogger,
} from '@concur/core-ui-shell';
import Logo from '../Logo/Logo';
import withLoggerData from '../utils/withLoggerData';
import * as styles from './AppIdleTimer-*.css';

const CSS_BLOCK = 'sapcnqr-app-idle-timer';

const IdleTimer = (props) => {
    const {
        classNameMap,
        debugSessionId,
        demo,
        expirationWarningInSeconds,
        formatter,
        logoutUser,
        idleTimeoutInMinutes,
        isLoggedIn,
        isRetiredBrand,
        pingSession,
        sessionTimeoutInMinutes,
        logger,
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    // short circuit if either not logged in or session timeout is disabled
    if (!isLoggedIn || sessionTimeoutInMinutes <= 0) return null;

    const convertMinutesToSeconds = (minutes) => Math.floor(minutes * 60);
    const convertSecondsToMillis = (seconds) => Math.floor(seconds * 1000);
    const convertMillisToSeconds = (milliseconds) => Math.floor(milliseconds / 1000);

    // if idle timer is disabled, no prop value comes in so just use the session timeout
    let idleTimeoutInMinutesCopy = idleTimeoutInMinutes || sessionTimeoutInMinutes;

    // idleTimeoutMinutes should not be greater than sessionTimeoutMinutes
    if (idleTimeoutInMinutesCopy > sessionTimeoutInMinutes) {
        idleTimeoutInMinutesCopy = sessionTimeoutInMinutes;
    }

    // timeout props come in as minutes, but everything works off seconds and milliseconds
    const idleTimeoutInSeconds = convertMinutesToSeconds(idleTimeoutInMinutesCopy);
    const sessionTimeoutInSeconds = convertMinutesToSeconds(sessionTimeoutInMinutes);

    const idleTimeoutInMillis = convertSecondsToMillis(idleTimeoutInSeconds);
    const sessionTimeoutInMillis = convertSecondsToMillis(sessionTimeoutInSeconds);

    const countdownTimeoutInSeconds = sessionTimeoutInSeconds >= idleTimeoutInSeconds
        ? sessionTimeoutInSeconds - idleTimeoutInSeconds
        : 0;

    // let's set a keep alive delay as to not overwhelm the servers
    const keepAliveDelayInMillis = Math.floor(sessionTimeoutInMillis / 3);

    const idleTimerRef = useRef();
    const countdownTimerRef = useRef();

    const [showModal, setShowModal] = useState(!!demo);
    const [idleTimerTimeout, setIdleTimerTimeout] = useState(idleTimeoutInMillis);
    const [countdownLengthInSeconds, setCountdownLengthInSeconds] = useState(countdownTimeoutInSeconds); // eslint-disable-line max-len
    const [countdownSecondsElapsed, setCountdownSecondsElapsed] = useState(0);
    const [countdownStartTime, setCountdownStartTime] = useState();
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());
    const [lastKeepAlivePingTime, setLastKeepAlivePingTime] = useState(Date.now());

    const logDebugMessage = (message) => {
        if (!debugSessionId) return;

        logger.serverDebug(`${new Date()} | Debug IdleTimer (${debugSessionId}) | ${message}`);
    };

    const renderTimeRemaining = () => {
        let secondsRemaining = countdownLengthInSeconds - countdownSecondsElapsed;
        if (secondsRemaining < 0) secondsRemaining = 0;

        const timeRemainingClasses = classnames(
            `${CSS_BLOCK}__time-remaining`,
            {
                [`${CSS_BLOCK}__time-remaining--expiring`]: secondsRemaining <= expirationWarningInSeconds,
            },
        );

        // values must be stringified since formattedMessage will not parse 0 as a valid value
        return (
            <span className={timeRemainingClasses}>
                {formatter.formattedMessage(
                    {
                        id: 'CoreUI.SessionTimeout.Modal.TimerFormat',
                        values: {
                            minutes: `${Math.floor(secondsRemaining / 60)}`,
                            seconds: `${Math.floor(secondsRemaining % 60)}`,
                        },
                    },
                )}
            </span>
        );
    };

    const keepSessionAlive = (forcePing) => {
        if (demo) return;

        logDebugMessage(`Keep session alive: ${forcePing}`);

        // to force a ping, rewind the last keep alive ping time
        if (forcePing) {
            setLastKeepAlivePingTime(lastKeepAlivePingTime - (keepAliveDelayInMillis + 1));
        }

        // set the state variable and let the useEffect do the processing
        setLastActivityTime(Date.now());
    };

    const onIdleTimeout = (countdownLengthInMillis) => {
        if (demo) return;

        logDebugMessage(`IDLE TIMEOUT EXPIRED: ${countdownLengthInMillis}`);

        // as a safeguard, make sure no previous countdown timer is still running
        clearInterval(countdownTimerRef.current);

        keepSessionAlive(true);

        // set state variables to trigger the countdown timer
        setCountdownLengthInSeconds(countdownLengthInMillis
            ? convertMillisToSeconds(countdownLengthInMillis)
            : countdownTimeoutInSeconds);
        setCountdownSecondsElapsed(0);
        setCountdownStartTime(Date.now());
    };

    const resetIdleTimer = (timeoutValue) => {
        if (demo) return;

        logDebugMessage(`Reset idle timer: ${timeoutValue}`);

        clearInterval(countdownTimerRef.current);
        setShowModal(false);

        // reset idle timer
        setIdleTimerTimeout(timeoutValue || idleTimeoutInMillis);
        idleTimerRef.current.reset();

        keepSessionAlive(true);
    };

    // this function handles the onClick from the "I need more time" button because that,
    // by default, sends an event paraemter and when resetting the idle timer, we want
    // to use a custom parameter
    const handleResetIdleTimer = () => {
        if (demo) return;

        logDebugMessage('Handle reset idle timer');

        resetIdleTimer();
    };

    const onSessionTimeout = () => {
        if (demo) return;

        logDebugMessage('SESSION TIMEOUT EXPIRED');

        clearInterval(countdownTimerRef.current);
        setShowModal(false);
        logoutUser();
    };

    const onAction = (e) => {
        if (demo) return;

        logDebugMessage(`Action detected: ${e.type}`);

        keepSessionAlive();
    };

    const handleVisibilityChange = () => {
        if (demo) return;

        logDebugMessage(`Visibility change detected: ${document.hidden}`);

        if (!document.hidden) {
            const currentTime = Date.now();
            const totalElapsedTime = currentTime - idleTimerRef.current?.getLastActiveTime();

            if (totalElapsedTime > sessionTimeoutInMillis) {
                // session timeout
                onSessionTimeout();
            } else if (totalElapsedTime > idleTimeoutInMillis) {
                // idle timeout, show countdown
                // we're not sure if the idle timer continued to run or not so let's just pause it
                // so we don't get any surprise events popping up
                idleTimerRef.current.pause();
                onIdleTimeout(sessionTimeoutInMillis - totalElapsedTime);
            } else {
                // idle timer still running, but let's reset it to make sure it's accurate
                resetIdleTimer(idleTimeoutInMillis - totalElapsedTime);
            }
        }
    };

    useEffect(() => {
        if (demo || !countdownStartTime) return;

        logDebugMessage(`Effect (countdownStartTime): ${countdownStartTime}, ${new Date(countdownStartTime)}`);

        let secondsElapsed = countdownSecondsElapsed;

        // conditionally show the modal to avoid showing it and
        // immediately dismissing it because the session timed out
        // Additonally, bypass the modal if idleTimeout is 0
        if (secondsElapsed < countdownLengthInSeconds && idleTimeoutInMinutes) {
            setShowModal(true);
        }

        // start a new countdown timer
        const timerId = setInterval(() => {
            secondsElapsed += 1;
            setCountdownSecondsElapsed(secondsElapsed);

            keepSessionAlive();

            if (secondsElapsed >= countdownLengthInSeconds) {
                // session timeout (countdown expired)
                clearInterval(timerId);
                onSessionTimeout();
            }
        }, 1000);

        countdownTimerRef.current = timerId;

        // eslint-disable-next-line consistent-return
        return () => {
            clearInterval(countdownTimerRef.current);
        };
    }, [countdownStartTime]);

    useEffect(() => {
        if (demo) return;

        logDebugMessage(`Effect (lastActivityTime): ${lastActivityTime}, ${new Date(lastActivityTime)}`);

        if (lastActivityTime > lastKeepAlivePingTime + keepAliveDelayInMillis) {
            logDebugMessage('Ping session');
            setLastKeepAlivePingTime(lastActivityTime);
            pingSession();
        }
    }, [lastActivityTime]);

    useEffect(() => {
        if (demo) return;

        logDebugMessage(`Effect (debugSessionId): ${debugSessionId}`);

        // let's get a set of relevant props to debug
        const {
            demo: test1,
            logoutUser: test2,
            pingSession: test3,
            logger: test4,
            formatter: test5,
            classNameMap: test6,
            ...debugProps
        } = props;

        logDebugMessage(`Props: ${JSON.stringify(debugProps)}`);
        logDebugMessage(`State: Idle timeout (sec) = ${idleTimeoutInSeconds}, Session timeout (sec) = ${sessionTimeoutInSeconds}`);
    }, [debugSessionId]);

    // When a tab is hidden, the browser will stop intervals after a period of time.
    // Recalculate the time remaining when the tab is shown.
    useEffect(() => {
        if (demo) return;
        // check for global forceResetIdleTimer variable set by TM_ResetTimeouts() in travelManager
        const forceResetIdleTimerInterval = setInterval(() => {
            if (window?.forceResetIdleTimer) {
                logDebugMessage('Effect (forceResetIdleTimer)');
                resetIdleTimer();
                window.forceResetIdleTimer = false;
            }
        }, 60000);

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // eslint-disable-next-line consistent-return
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearInterval(forceResetIdleTimerInterval);
        };
    }, []);

    return (
        <>
            {!demo && (
                <ReactIdleTimer
                    debounce={250}
                    events={[
                        'mousemove',
                        'keydown',
                        'wheel',
                        'DOMMouseScroll',
                        'mouseWheel',
                        'mousedown',
                        'touchstart',
                        'touchmove',
                        'MSPointerDown',
                        'MSPointerMove',
                    ]} // removed 'visibilitychange' from list of default events
                    onAction={onAction}
                    onIdle={onIdleTimeout}
                    ref={idleTimerRef}
                    stopOnIdle
                    timeout={idleTimerTimeout}
                />
            )}
            {debugSessionId && (
                <Badge className={classnames(`${CSS_BLOCK}__session-badge`)}>
                    Idle Timer Debug
                    <br />
                    {debugSessionId}
                </Badge>
            )}
            <Modal
                backdropClassName={classnames(`${CSS_BLOCK}__backdrop`)}
                className={classnames(CSS_BLOCK)}
                modalContainerClassName={classnames(`${CSS_BLOCK}__container`)}
                onHide={handleResetIdleTimer}
                show={showModal}
            >
                <Modal.Header className={classnames(`${CSS_BLOCK}__header`)}>
                    <Modal.Title>
                        {formatter.formattedMessage({ id: 'CoreUI.SessionTimeout.Modal.Title' })}
                    </Modal.Title>
                    <div className={classnames(`${CSS_BLOCK}__container-logo`)}>
                        <Logo
                            className={classnames(`${CSS_BLOCK}__logo`, {
                                [`${CSS_BLOCK}__logo--retired`]: isRetiredBrand,
                            })}
                            classNameMap={classNameMap}
                            cssBlock={CSS_BLOCK}
                            isRetiredBrand={isRetiredBrand}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <p className={classnames(`${CSS_BLOCK}__paragraph`)}>{formatter.formattedMessage({ id: 'CoreUI.SessionTimeout.Modal.Message1' })}</p>
                    <p className={classnames(`${CSS_BLOCK}__paragraph`)}>{formatter.formattedMessage({ id: 'CoreUI.SessionTimeout.Modal.Message2' })}</p>
                    <p className={classnames(`${CSS_BLOCK}__paragraph`)}>
                        {formatter.formattedMessage({ id: 'CoreUI.SessionTimeout.Modal.TimerPrefix' })}
                        &nbsp;
                        {renderTimeRemaining()}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleResetIdleTimer}>
                        {formatter.formattedMessage({ id: 'CoreUI.SessionTimeout.Modal.MoreTime' })}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

IdleTimer.displayName = 'IdleTimer';

IdleTimer.propTypes = {
    demo: PropTypes.bool,
    expirationWarningInSeconds: PropTypes.number,
    idleTimeoutInMinutes: PropTypes.number, // in session as "LockTimeout"
    isLoggedIn: PropTypes.bool,
    isRetiredBrand: PropTypes.bool,
    logoutUser: PropTypes.func,
    sessionTimeoutInMinutes: PropTypes.number,
    pingSession: PropTypes.func,
};

IdleTimer.defaultProps = {
    expirationWarningInSeconds: 30,
    sessionTimeoutInMinutes: 20,
    pingSession: () => {},
    logoutUser: () => {},
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
    withErrorBoundary,
    withLoggerData,
    withLogger,
)(IdleTimer);
