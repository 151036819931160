/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classnamesBind from 'classnames/bind';
import { v4 as uuidv4 } from 'uuid';
import md5 from 'md5';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import Checkbox from '@concur/nui-widgets/lib/Forms/Checkbox';
import ComboBox from '@concur/nui-widgets/lib/ComboBox/ComboBox';
import ControlLabel from '@concur/nui-widgets/lib/Forms/ControlLabel';
import Fieldset from '@concur/nui-widgets/lib/Forms/Fieldset';
import FormGroup from '@concur/nui-widgets/lib/Forms/FormGroup';
import QuickHelp from '@concur/nui-widgets/lib/Popover/QuickHelp';
import RadioGroup from '@concur/nui-widgets/lib/Forms/RadioGroup';
import Radio from '@concur/nui-widgets/lib/Forms/Radio';
import Spinner from '@concur/nui-widgets/lib/Spinner/Spinner';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import {
    compose, withLogger, getValues, useDebounce, usePrevious,
} from '@concur/core-ui-shell';
import {
    ACTING_AS_OPTIONS,
    ENDPOINT_URLS,
} from '../constants';
import {
    endSessionPost,
    getClientSideSearchResults,
    listItemRenderer,
    startSessionPost,
} from './_helpers';
import InlineError from '../FallbackComponent/_InlineError';
import withLoggerData from '../utils/withLoggerData';
import * as styles from './ActingAsFiori-*.css';

const CSS_PREFIX = 'sapcnqr';
const CSS_BLOCK = 'sapcnqr-acting-as-fiori';
const SEARCH_RESULTS_ID_FIELD = 'ProtectedId';
const ALL_RESULTS_RETURNED = 'ALL_RESULTS_RETURNED';
const MOST_RECENTLY_USED = 'MOST_RECENTLY_USED';

const ActingAsFiori = ({
    classNameMap,
    formatter,
    isActingForOthers,
    isGov,
    logger,
    onEndLoading,
    onEndSession,
    onStartLoading,
    onStartSession,
    options,
    quickHelpRef,
    userDropdownRef,
}) => {
    const classnames = classnamesBind.bind(classNameMap);

    const sortedOptions = options.sort();
    const adjustedOptions = sortedOptions.length > 0 ? [ACTING_AS_OPTIONS.MYSELF, ...sortedOptions] : [];
    const optionsCount = adjustedOptions.length;

    const defaultNoResults = formatter.formattedMessage({ id: 'CoreUI.warning.noResults' });
    const [selectedOption, setSelectedOption] = useState(optionsCount > 0 ? adjustedOptions[0] : '');
    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState();
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [noResultsFound, setNoResultsFound] = useState(defaultNoResults);
    const [error, setError] = useState();
    const [correlationId, setCorrelationId] = useState();
    const [searchTypes, setSearchTypes] = useState({});
    const [searchResults, setSearchResults] = useState({});
    const queryTerm = useDebounce(searchText);
    const previousSelectedOption = usePrevious(selectedOption);

    useEffect(() => {
        setSearchText('');
        setListData([]);
        setNoResultsFound(defaultNoResults);
    }, [selectedOption]);

    useEffect(() => {
        let isCancelled = false;

        const getListData = async (searchPattern, option) => {
            const tmpCorrelationId = uuidv4();
            setIsLoading(true);
            try {
                const response = await axios.get(ENDPOINT_URLS.ACTING_AS_LIST, {
                    params: {
                        incDelegatees: option === ACTING_AS_OPTIONS.DELEGATE,
                        incProxies: option === ACTING_AS_OPTIONS.PROXY,
                        incSelfAssign: option === ACTING_AS_OPTIONS.SELF_ASSIGN,
                        searchPattern,
                    },
                    headers: { 'concur-correlationid': tmpCorrelationId },
                });
                if (isCancelled) {
                    return;
                }
                const searchResult = response.data?.ProxyAsList || [];
                const didReceiveMostRecentUsers = searchResult.length > 0
                    && searchPattern === '*'
                    && response.data?.SearchType === 0;
                if (didReceiveMostRecentUsers) {
                    setSearchTypes({
                        ...searchTypes,
                        [option]: MOST_RECENTLY_USED,
                    });
                } else {
                    setSearchTypes({
                        ...searchTypes,
                        [option]: null,
                    });
                }

                const didReceiveAllResults = searchPattern === '*' && response.data?.SearchType === 1;
                // we just got all the results so don't make the query again, instead keep the data available client side
                if (didReceiveAllResults) {
                    setSearchTypes({
                        ...searchTypes,
                        [option]: ALL_RESULTS_RETURNED,
                    });
                    const localSearchResults = {};
                    searchResult.forEach((result) => {
                        localSearchResults[result[SEARCH_RESULTS_ID_FIELD]] = result;
                    });
                    setSearchResults({
                        ...searchResults,
                        [option]: localSearchResults,
                    });
                } else {
                    const emptyResults = response.data?.ProxyAsList?.length === 0
                        && response.data?.SearchType === 1;
                    setListData(response.data?.ProxyAsList || []);
                    if (emptyResults) {
                        setNoResultsFound(defaultNoResults);
                    } else {
                        setNoResultsFound({
                            title: formatter.formattedMessage({ id: 'CoreUI.warning.tooManyResults' }),
                            description: formatter.formattedMessage({ id: 'CoreUI.warning.enterAdditionalSearchCriteria' }),
                        });
                    }
                }
            } catch (err) {
                logger.error(`Fetching Acting as list: ${err.message}`, { correlationId: tmpCorrelationId });
                setError(formatter.formattedMessage({ id: 'CoreUI.error.errorFetching' }));
                setCorrelationId(tmpCorrelationId);
            }
            if (!isCancelled) {
                setIsLoading(false);
            }
        };
        if (searchTypes[selectedOption] !== ALL_RESULTS_RETURNED) {
            if (previousSelectedOption === selectedOption) {
                getListData(queryTerm, selectedOption);
            } else if (queryTerm === '') {
                // selected option was changed
                getListData('*', selectedOption);
            }
        }

        return () => {
            isCancelled = true;
        };
    }, [queryTerm, selectedOption]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setSearchText('');
        setSelectedUser();
    };

    const handleChange = (event) => {
        setSearchText(event?.target?.value || '');
        if (!event.target.value) {
            setSelectedUser();
        }
    };

    const handleSelect = (event, item) => {
        if (item[SEARCH_RESULTS_ID_FIELD] !== -1 && item?.Text !== '') {
            setSelectedUser(item[SEARCH_RESULTS_ID_FIELD]);
            setSearchText(item.Text);
        } else {
            setSelectedUser();
        }
    };

    const handleStartSession = async () => {
        onStartLoading();
        const { tmpCorrelationId, response, sessionError } = await startSessionPost(selectedOption, selectedUser, isGov, checked);

        if (response) {
            if (response.data?.Status === 'OK') {
                onStartSession(response.data?.Url);
            } else {
                logger.error(`Starting Acting as session: ${response.data?.Status}`, { correlationId: tmpCorrelationId });
                setError(response.data?.Message || formatter.formattedMessage({ id: 'CoreUI.error.errorSwitching' }));
                setCorrelationId(tmpCorrelationId);
                onEndLoading();
            }
        } else if (sessionError) {
            logger.error(`Starting Acting as session: ${sessionError.message}`, { correlationId: tmpCorrelationId });
            setError(formatter.formattedMessage({ id: 'CoreUI.error.errorSwitching' }));
            setCorrelationId(tmpCorrelationId);
            onEndLoading();
        }
    };

    const handleEndSession = async () => {
        onStartLoading();
        const { tmpCorrelationId, response, sessionError } = await endSessionPost();

        if (response) {
            if (response.data?.Status === 'OK') {
                onEndSession(response.data?.Url);
            } else {
                logger.error(`Ending Acting as session: ${response.data?.Status}`, { correlationId: tmpCorrelationId });
                setError(response.data?.Message || formatter.formattedMessage({ id: 'CoreUI.error.errorSwitching' }));
                setCorrelationId(tmpCorrelationId);
                onEndLoading();
            }
        } else if (sessionError) {
            logger.error(`Ending Acting as session: ${sessionError.message}`, { correlationId: tmpCorrelationId });
            setError(formatter.formattedMessage({ id: 'CoreUI.error.errorSwitching' }));
            setCorrelationId(tmpCorrelationId);
            onEndLoading();
        }
    };

    const placeholderText = formatter.formattedMessage({ id: 'CoreUI.placeholder.searchUser' });

    const allResultsReturned = searchTypes[selectedOption] === ALL_RESULTS_RETURNED;
    const mruResultsReturned = searchTypes[selectedOption] === MOST_RECENTLY_USED;
    const comboBoxData = allResultsReturned
        ? getClientSideSearchResults(searchText, searchResults, selectedOption)
        : listData;
    const switchSessionEnabled = (selectedUser || selectedOption === ACTING_AS_OPTIONS.GOV_ALL_USERS)
        || (isActingForOthers && selectedOption === ACTING_AS_OPTIONS.MYSELF);

    return (
        <>
            {optionsCount > 0 && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                    className={classnames(CSS_PREFIX, CSS_BLOCK)}
                    data-test="acting-as"
                    /* need to prevent ListItem from eating mouse / keyboard inputs */
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    aria-label={formatter.formattedMessage({ id: 'CoreUI.actingAsTitle' })}
                >
                    <Fieldset
                        className={classnames(CSS_PREFIX, `${CSS_BLOCK}__fieldset`)}
                        legend={(
                            <div className={classnames(CSS_PREFIX, `${CSS_BLOCK}__fieldset-legend`)}>
                                {formatter.formattedMessage({ id: 'CoreUI.actingAsTitle' })}
                                <QuickHelp
                                    className={classnames(CSS_PREFIX, `${CSS_BLOCK}__quick-help`)}
                                    data-test="acting-as-quick-help"
                                    message={adjustedOptions.map((type) => (
                                        <div className={classnames(CSS_PREFIX, `${CSS_BLOCK}__option`)} key={type}>
                                            <div className={classnames(CSS_PREFIX, `${CSS_BLOCK}__option-title`)}>
                                                {formatter.formattedMessage({ id: `CoreUI.quickHelpFiori.${type}.title` })}
                                            </div>
                                            {formatter.formattedMessage({ id: `CoreUI.quickHelpFiori.${type}.description` })}
                                        </div>
                                    ))}
                                    placement="right-start"
                                    popoverProps={{
                                        className: classnames(CSS_PREFIX, `${CSS_BLOCK}__quick-help-popper`, 'ignore-react-onclickoutside'),
                                        popperProps: {
                                            innerRef: quickHelpRef,
                                        },
                                    }}
                                    textClose={formatter.formattedMessage({ id: 'CoreUI.close' })}
                                    textShowQuickHelp={formatter.formattedMessage({ id: 'CoreUI.quickHelpTitleActingAsOtherUser' })}
                                    title={formatter.formattedMessage({ id: 'CoreUI.quickHelpTitleActingAsOtherUserFiori' })}
                                />
                            </div>
                        )}
                    >
                        {optionsCount > 1 && (
                            <RadioGroup
                                className={classnames(CSS_PREFIX, `${CSS_BLOCK}__radio-group`)}
                                label={formatter.formattedMessage({ id: 'CoreUI.selectOption' })}
                                labelProps={{
                                    className: classnames(CSS_PREFIX, `${CSS_BLOCK}__radio-group-label`),
                                }}
                                name="cnqr-profile-popup-radio"
                                onChange={handleOptionChange}
                            >
                                {adjustedOptions.map((type) => (
                                    <Radio
                                        checked={type === selectedOption}
                                        data-test={`acting-as-radio-${type}`}
                                        id={`cnqr-profile-popup-radio-${type}`}
                                        key={type}
                                        value={type}
                                    >
                                        {formatter.formattedMessage({ id: `CoreUI.actingAsOptionLabelFiori.${type}` })}
                                    </Radio>
                                ))}
                            </RadioGroup>
                        )}
                        {error && (
                            <InlineError
                                className={classnames(CSS_PREFIX, `${CSS_BLOCK}__error`)}
                                correlationId={correlationId}
                                message={error}
                            />
                        )}
                        {!error && (
                            <div className={classnames(CSS_PREFIX, `${CSS_BLOCK}__session-start`)}>
                                {selectedOption === ACTING_AS_OPTIONS.GOV_ALL_USERS ? (
                                    <Checkbox
                                        checked={checked}
                                        data-test="acting-as-checkbox-tavs"
                                        labelClassName={classnames(CSS_PREFIX, `${CSS_BLOCK}__checkbox`)}
                                        label={formatter.formattedMessage({ id: 'CoreUI.actingAsCheckbox' })}
                                        onChange={() => setChecked(!checked)}
                                    />
                                ) : (
                                    <FormGroup className={classnames(CSS_PREFIX, `${CSS_BLOCK}__form-group`)} controlId="act-as-search" size="md">
                                        <ControlLabel className={classnames(CSS_PREFIX, `${CSS_BLOCK}--screen-reader-only`)}>
                                            {placeholderText}
                                        </ControlLabel>
                                        <ComboBox
                                            options={comboBoxData.map((user) => ({
                                                ...user,
                                                // lets use safeId as an
                                                // itemKey than can never collide with
                                                // the listbox separator
                                                safeId: md5(user?.[SEARCH_RESULTS_ID_FIELD], { encoding: 'hex' }),
                                            }))}
                                            dataRendering={{
                                                inputRenderer: 'Text',
                                                listItemRenderer: (itemData) => listItemRenderer(itemData, CSS_BLOCK, classnames),
                                            }}
                                            disabled={selectedOption === ACTING_AS_OPTIONS.MYSELF}
                                            dropdownTriggerIconName="search"
                                            dropdownTriggerLabel={formatter.formattedMessage({ id: 'CoreUI.expand' })}
                                            filterable={false}
                                            inputProps={{
                                                'data-test': 'acting-as-search-input',
                                            }}
                                            isLoading={!allResultsReturned && isLoading}
                                            itemKey="safeId"
                                            listItemProps={{
                                                itemWrapperProps: {
                                                    className: classnames(CSS_PREFIX, `${CSS_BLOCK}__list-item-content`),
                                                },
                                            }}
                                            loadingMsg={(
                                                <Spinner
                                                    message={formatter.formattedMessage({ id: 'CoreUI.loading' })}
                                                    size="lg"
                                                    type="inline"
                                                    visible
                                                />
                                            )}
                                            mruOptions={mruResultsReturned ? comboBoxData : []}
                                            mruTitle={formatter.formattedMessage({ id: 'CoreUI.mostRecentUsers' })}
                                            noResultsMsg={noResultsFound}
                                            onChange={handleChange}
                                            onSelectionChange={handleSelect}
                                            placeholder={placeholderText}
                                            popoverProps={{ className: 'ignore-react-onclickoutside' }}
                                            popperContentRef={userDropdownRef}
                                            showOnFocus={() => true}
                                            value={searchText}
                                        />
                                    </FormGroup>
                                )}
                                <Button
                                    className={classnames(CSS_PREFIX, `${CSS_BLOCK}__session-start-button`)}
                                    data-test="acting-as-session-start"
                                    disabled={!switchSessionEnabled}
                                    onClick={selectedOption === ACTING_AS_OPTIONS.MYSELF ? handleEndSession : handleStartSession}
                                    size="md"
                                >
                                    {formatter.formattedMessage({ id: 'CoreUI.switch' })}
                                </Button>
                            </div>
                        )}
                    </Fieldset>
                </div>
            )}
        </>
    );
};

ActingAsFiori.displayName = 'ActingAsFiori';

ActingAsFiori.propTypes = {
    isActingForOthers: PropTypes.bool,
    isGov: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.oneOf(getValues(ACTING_AS_OPTIONS)),
    ),
    onEndSession: PropTypes.func,
    onEndLoading: PropTypes.func,
    onStartLoading: PropTypes.func,
    onStartSession: PropTypes.func,
    quickHelpRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    userDropdownRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

ActingAsFiori.defaultProps = {
    options: [],
    onEndSession: () => {},
    onEndLoading: () => {},
    onStartSession: () => {},
    onStartLoading: () => {},
};

export default compose(
    withThemeStyles(styles),
    withLoggerData,
    withLogger,
    withFormatter,
)(ActingAsFiori);
