import Cookies from 'universal-cookie';

const COOKIE_NAME = 'LocalizationMode';

export const isLocalizationModeEnabled = () => {
    const cookies = new Cookies();
    const localizationMode = cookies.get(COOKIE_NAME);
    return localizationMode?.toLowerCase() === 'true';
};

export const setLocalizationMode = (enabled) => {
    const cookies = new Cookies();
    if (enabled) {
        cookies.set(COOKIE_NAME, 'true', { path: '/' });
    } else {
        cookies.remove(COOKIE_NAME, { path: '/' });
    }
    window.location.reload();
};
