import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import ConcurLogo from './_ConcurLogo';
import GovLogo from './_GovLogo';
import SapConcurLogo from './_SapConcurLogo';
import SapLogo from './_SapLogo';

const Logo = (props) => {
    const {
        ariaHidden,
        className,
        cssBlock,
        classNameMap,
        isFiori,
        isGov,
        isRetiredBrand,
        useSapLogo,
        ...otherProps
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const logoFillClasses = classnames(
        `${cssBlock}__logo--fill1`,
        {
            [`${cssBlock}__logo-gov--fill1`]: isGov,
        },
    );

    const logoFill2Classes = classnames(
        `${cssBlock}__logo--fill2`,
        {
            [`${cssBlock}__logo-gov--fill2`]: isGov,
        },
    );

    const logoFill3Classes = classnames(
        {
            [`${cssBlock}__logo--fill3`]: useSapLogo,
        },
    );

    const logoFill4Classes = classnames(
        {
            [`${cssBlock}__logo--fill4`]: useSapLogo,
        },
    );

    const componentProps = {
        'aria-hidden': ariaHidden,
        className: classnames(className),
        fill1ClassName: logoFillClasses,
        fill2ClassName: logoFill2Classes,
        ...otherProps,
    };

    if (useSapLogo) {
        return (
            <SapLogo
                {...componentProps}
                fill3ClassName={logoFill3Classes}
                fill4ClassName={logoFill4Classes}
                isFiori={isFiori}
            />
        );
    }
    if (isGov) {
        return <GovLogo {...componentProps} />;
    } if (isRetiredBrand) {
        return <ConcurLogo {...componentProps} />;
    }
    return <SapConcurLogo {...componentProps} />;
};

Logo.displayName = 'Logo';

Logo.propTypes = {
    ariaHidden: PropTypes.bool,
    className: PropTypes.string,
    cssBlock: PropTypes.string,
    isGov: PropTypes.bool,
    isFiori: PropTypes.bool,
    isRetiredBrand: PropTypes.bool,
    useSapLogo: PropTypes.bool,
};

export default Logo;
