/* eslint-disable react/prop-types */
import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { isFiori } from '@concur/ui-theming';
import { GATEWAY_BRANDING_ID } from '@concur/core-ui-shell/lib/constants';
import PlaceholderComponent from '../PlaceholderComponent/_PlaceholderComponent';

export default (WrappedComponent) => {
    const Component = (props) => {
        const {
            menuData,
            brandingId,
            themeName,
            useFullWidth,
            useSmallViewportBreakpoints,
        } = props;

        const {
            pageMenuItems = [],
        } = menuData || {};

        // sometimes the theme cookie value gets encoded so we need to decode it here
        const decodedThemeName = themeName ? decodeURI(themeName) : undefined;

        let componentType;

        if (!WrappedComponent.cssBlock
                && (menuData || props.onStartSession || props.actingAs)) {
            componentType = 'header';
        } else if (WrappedComponent.cssBlock === 'sapcnqr-app-footer') {
            componentType = 'footer';
        } else if (WrappedComponent.cssBlock === 'sapcnqr-app-mini-header') {
            componentType = 'mini-header';
        }

        if (componentType) {
            componentType = `${componentType}-${isFiori(brandingId, decodedThemeName) ? 'fiori' : 'gateway'}`;
        }

        const placeholderComp = () => (
            <PlaceholderComponent
                hasAdditionalContent={pageMenuItems && pageMenuItems.length > 0}
                originalComponentType={componentType}
                useFullWidth={useFullWidth}
                useSmallViewportBreakpoints={useSmallViewportBreakpoints}
            />
        );

        return (
            <WrappedComponent
                {...props}
                cssFilenamePrefix="main"
                placeholderComp={placeholderComp}
            />
        );
    };

    hoistNonReactStatics(Component, WrappedComponent);

    Component.defaultProps = {
        brandingId: GATEWAY_BRANDING_ID,
    };
    Component.propTypes = WrappedComponent.propTypes;
    Component.displayName = WrappedComponent.displayName || WrappedComponent.name || 'withStylesPlaceholder';

    return Component;
};
