import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classnamesBind from 'classnames/bind';
import { v4 as uuidv4 } from 'uuid';
import md5 from 'md5';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import ButtonToolbar from '@concur/nui-widgets/lib/Buttons/ButtonToolbar';
import Checkbox from '@concur/nui-widgets/lib/Forms/Checkbox';
import ComboBox from '@concur/nui-widgets/lib/ComboBox/ComboBox';
import ControlLabel from '@concur/nui-widgets/lib/Forms/ControlLabel';
import Fieldset from '@concur/nui-widgets/lib/Forms/Fieldset';
import FormGroup from '@concur/nui-widgets/lib/Forms/FormGroup';
import Icon from '@concur/nui-widgets/lib/Icon/Icon';
import QuickHelp from '@concur/nui-widgets/lib/Popover/QuickHelp';
import RadioGroup from '@concur/nui-widgets/lib/Forms/RadioGroup';
import Radio from '@concur/nui-widgets/lib/Forms/Radio';
import Spinner from '@concur/nui-widgets/lib/Spinner/Spinner';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import {
    compose, withLogger, getValues, useDebounce, usePrevious,
} from '@concur/core-ui-shell';
import {
    ACTING_AS_OPTIONS,
    ENDPOINT_URLS,
} from '../constants';
import {
    endSessionPost,
    getClientSideSearchResults,
    listItemRenderer,
    startSessionPost,
} from './_helpers';
import InlineError from '../FallbackComponent/_InlineError';
import withLoggerData from '../utils/withLoggerData';
import * as styles from './ActingAs-*.css';

const CSS_BLOCK = 'sapcnqr-acting-as';
const SEARCH_RESULTS_ID_FIELD = 'ProtectedId';
const ALL_RESULTS_RETURNED = 'ALL_RESULTS_RETURNED';

const ActingAs = ({
    classNameMap,
    formatter,
    isActingForOthers,
    isGov,
    logger,
    onClose,
    onEndLoading,
    onEndSession,
    onStartLoading,
    onStartSession,
    options,
    quickHelpRef,
    userDropdownRef,
}) => {
    const classnames = classnamesBind.bind(classNameMap);

    const optionsCount = options.length;

    const sortedOptions = options.sort();
    const defaultNoResults = formatter.formattedMessage({ id: 'CoreUI.warning.noResults' });
    const [selectedOption, setSelectedOption] = useState(optionsCount > 0 ? sortedOptions[0] : '');
    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState();
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [noResultsFound, setNoResultsFound] = useState(defaultNoResults);
    const [error, setError] = useState();
    const [correlationId, setCorrelationId] = useState();
    const [searchTypes, setSearchTypes] = useState({});
    const [searchResults, setSearchResults] = useState({});
    const [mruProps, setMruProps] = useState({});
    const queryTerm = useDebounce(searchText);
    const previousSelectedOption = usePrevious(selectedOption);

    useEffect(() => {
        setSearchText('');
        setListData([]);
        setNoResultsFound(defaultNoResults);
    }, [selectedOption]);

    useEffect(() => {
        let isCancelled = false;

        const getListData = async (searchPattern, option) => {
            const tmpCorrelationId = uuidv4();
            setIsLoading(true);
            try {
                const response = await axios.get(ENDPOINT_URLS.ACTING_AS_LIST, {
                    params: {
                        incDelegatees: option === ACTING_AS_OPTIONS.DELEGATE,
                        incProxies: option === ACTING_AS_OPTIONS.PROXY,
                        incSelfAssign: option === ACTING_AS_OPTIONS.SELF_ASSIGN,
                        searchPattern,
                    },
                    headers: { 'concur-correlationid': tmpCorrelationId },
                });
                if (isCancelled) {
                    return;
                }
                let responseDataList = response.data?.ProxyAsList || [];
                const responseSearchType = response.data?.SearchType;

                const didReceiveMostRecentUsers = responseDataList.length > 0 && searchPattern === '*' && responseSearchType === 0;
                if (didReceiveMostRecentUsers) {
                    // add Most Recent users header
                    responseDataList = responseDataList.map((eachResult) => ({ ...eachResult, mruHeader: formatter.formattedMessage({ id: 'CoreUI.mostRecentUsers' }) }));
                    setMruProps({
                        groupBy: 'mruHeader',
                    });
                } else {
                    setMruProps({});
                }

                const didReceiveAllResults = searchPattern === '*' && responseSearchType === 1;
                // we just got all the results so don't make the query again,
                // instead keep the data available client side
                if (didReceiveAllResults) {
                    setSearchTypes({
                        ...searchTypes,
                        [option]: ALL_RESULTS_RETURNED,
                    });
                    const localSearchResults = {};
                    responseDataList.forEach((result) => {
                        localSearchResults[result[SEARCH_RESULTS_ID_FIELD]] = result;
                    });
                    setSearchResults({
                        ...searchResults,
                        [option]: localSearchResults,
                    });
                } else {
                    const emptyResults = responseDataList.length === 0 && responseSearchType === 1;
                    setListData(responseDataList);
                    if (emptyResults) {
                        setNoResultsFound(defaultNoResults);
                    } else {
                        setNoResultsFound({
                            title: formatter.formattedMessage({ id: 'CoreUI.warning.tooManyResults' }),
                            description: formatter.formattedMessage({ id: 'CoreUI.warning.enterAdditionalSearchCriteria' }),
                        });
                    }
                }
            } catch (err) {
                logger.error(`Fetching Acting as list: ${err.message}`, { correlationId: tmpCorrelationId });
                setError(formatter.formattedMessage({ id: 'CoreUI.error.errorFetching' }));
                setCorrelationId(tmpCorrelationId);
            }
            if (!isCancelled) {
                setIsLoading(false);
            }
        };
        if (searchTypes[selectedOption] !== ALL_RESULTS_RETURNED) {
            if (previousSelectedOption === selectedOption) {
                getListData(queryTerm, selectedOption);
            } else if (queryTerm === '') {
                // selected option was changed
                getListData('*', selectedOption);
            }
        }

        return () => {
            isCancelled = true;
        };
    }, [queryTerm, selectedOption]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setSearchText('');
        setSelectedUser();
    };

    const handleChange = (event) => {
        setSearchText(event.target.value);
        if (!event.target.value) {
            setSelectedUser();
        }
    };

    const handleSelect = (event, item) => {
        if (item[SEARCH_RESULTS_ID_FIELD] !== -1 && item?.Text !== '') {
            setSelectedUser(item[SEARCH_RESULTS_ID_FIELD]);
            setSearchText(item.Text);
        } else {
            setSelectedUser();
        }
    };

    const handleStartSession = async () => {
        onStartLoading();
        const {
            tmpCorrelationId,
            response,
            sessionError,
        } = await startSessionPost(selectedOption, selectedUser, isGov, checked);

        if (response) {
            if (response.data?.Status === 'OK') {
                onStartSession(response.data?.Url);
            } else {
                logger.error(`Starting Acting as session: ${response.data?.Status}`, { correlationId: tmpCorrelationId });
                setError(response.data?.Message || formatter.formattedMessage({ id: 'CoreUI.error.errorSwitching' }));
                setCorrelationId(tmpCorrelationId);
                onEndLoading();
            }
        } else if (sessionError) {
            logger.error(`Starting Acting as session: ${sessionError.message}`, { correlationId: tmpCorrelationId });
            setError(formatter.formattedMessage({ id: 'CoreUI.error.errorSwitching' }));
            setCorrelationId(tmpCorrelationId);
            onEndLoading();
        }
    };

    const handleEndSession = async () => {
        onStartLoading();
        const { tmpCorrelationId, response, sessionError } = await endSessionPost();

        if (response) {
            if (response.data?.Status === 'OK') {
                onEndSession(response.data?.Url);
            } else {
                logger.error(`Ending Acting as session: ${response.data?.Status}`, { correlationId: tmpCorrelationId });
                setError(response.data?.Message || formatter.formattedMessage({ id: 'CoreUI.error.errorSwitching' }));
                setCorrelationId(tmpCorrelationId);
                onEndLoading();
            }
        } else if (sessionError) {
            logger.error(`Ending Acting as session: ${sessionError.message}`, { correlationId: tmpCorrelationId });
            setError(formatter.formattedMessage({ id: 'CoreUI.error.errorSwitching' }));
            setCorrelationId(tmpCorrelationId);
            onEndLoading();
        }
    };

    const placeholderText = formatter.formattedMessage({ id: 'CoreUI.placeholder.searchUser' });

    const allResultsReturned = searchTypes[selectedOption] === ALL_RESULTS_RETURNED;
    const comboboxData = allResultsReturned
        ? getClientSideSearchResults(searchText, searchResults, selectedOption)
        : listData;
    const disableStartSession = !selectedUser && selectedOption !== ACTING_AS_OPTIONS.GOV_ALL_USERS;
    return (
        <>
            {optionsCount > 0 && (
                <div className={classnames(CSS_BLOCK)} data-test="acting-as">
                    <Icon
                        ariaHidden
                        className={classnames(`${CSS_BLOCK}__icon`)}
                        iconName="shuffle"
                        size="md"
                    />
                    <Fieldset
                        className={classnames(`${CSS_BLOCK}__fieldset`)}
                        legend={(
                            <>
                                {formatter.formattedMessage({ id: 'CoreUI.actingAsOtherUser' })}
                                <QuickHelp
                                    className={classnames(`${CSS_BLOCK}__quick-help`)}
                                    data-test="acting-as-quick-help"
                                    message={sortedOptions.map((type) => (
                                        <div className={classnames(`${CSS_BLOCK}__option`)} key={type}>
                                            <div className={classnames(`${CSS_BLOCK}__option-title`)}>
                                                {formatter.formattedMessage({ id: `CoreUI.quickHelp.${type}.title` })}
                                            </div>
                                            {formatter.formattedMessage({ id: `CoreUI.quickHelp.${type}.description` })}
                                        </div>
                                    ))}
                                    placement="right-start"
                                    popoverProps={{
                                        className: classnames(`${CSS_BLOCK}__quick-help-popper`, 'ignore-react-onclickoutside'),
                                        popperProps: {
                                            innerRef: quickHelpRef,
                                        },
                                    }}
                                    textClose={formatter.formattedMessage({ id: 'CoreUI.close' })}
                                    textShowQuickHelp={formatter.formattedMessage({ id: 'CoreUI.quickHelpTitleActingAsOtherUser' })}
                                    title={formatter.formattedMessage({ id: 'CoreUI.quickHelpTitleActingAsOtherUser' })}
                                />
                            </>
                        )}
                    >
                        {optionsCount > 1 && (
                            <RadioGroup
                                className={classnames(`${CSS_BLOCK}__radio-group`)}
                                label={formatter.formattedMessage({ id: 'CoreUI.selectOption' })}
                                labelProps={{
                                    className: classnames(`${CSS_BLOCK}__radio-group-label`),
                                }}
                                name="cnqr-profile-popup-radio"
                                onChange={handleOptionChange}
                            >
                                {sortedOptions.map((type) => (
                                    <Radio
                                        checked={type === selectedOption}
                                        data-test={`acting-as-radio-${type}`}
                                        id={`cnqr-profile-popup-radio-${type}`}
                                        key={type}
                                        value={type}
                                    >
                                        {formatter.formattedMessage({ id: `CoreUI.actingAsOptionLabel.${type}` })}
                                    </Radio>
                                ))}
                            </RadioGroup>
                        )}
                        {error && (
                            <InlineError
                                className={classnames(`${CSS_BLOCK}__error`)}
                                correlationId={correlationId}
                                message={error}
                            />
                        )}
                        {!error && (
                            <>
                                {selectedOption === ACTING_AS_OPTIONS.GOV_ALL_USERS ? (
                                    <Checkbox
                                        checked={checked}
                                        data-test="acting-as-checkbox-tavs"
                                        labelClassName={classnames(`${CSS_BLOCK}__checkbox`)}
                                        label={formatter.formattedMessage({ id: 'CoreUI.actingAsCheckbox' })}
                                        onChange={() => setChecked(!checked)}
                                    />
                                ) : (
                                    <FormGroup controlId="act-as-search" size="md">
                                        <ControlLabel className={classnames(`${CSS_BLOCK}--screen-reader-only`)}>
                                            {placeholderText}
                                        </ControlLabel>
                                        <ComboBox
                                            {...mruProps}
                                            options={comboboxData.map((user) => ({
                                                ...user,
                                                // lets use safeId as an
                                                // itemKey than can never collide with
                                                // the listbox separator
                                                safeId: md5(user?.[SEARCH_RESULTS_ID_FIELD], { encoding: 'hex' }),
                                            }))}
                                            dataRendering={{
                                                inputRenderer: 'Text',
                                                listItemRenderer: (itemData) => (
                                                    listItemRenderer(
                                                        itemData,
                                                        CSS_BLOCK,
                                                        classnames,
                                                    )
                                                ),
                                            }}
                                            dropdownTriggerIconName="search"
                                            dropdownTriggerLabel={formatter.formattedMessage({ id: 'CoreUI.expand' })}
                                            inputProps={{
                                                'data-test': 'acting-as-search-input',
                                            }}
                                            isLoading={!allResultsReturned && isLoading}
                                            itemKey="safeId"
                                            loadingMsg={(
                                                <Spinner
                                                    message={formatter.formattedMessage({ id: 'CoreUI.loading' })}
                                                    size="lg"
                                                    type="inline"
                                                    visible
                                                />
                                            )}
                                            noResultsMsg={noResultsFound}
                                            onChange={handleChange}
                                            onSelectionChange={handleSelect}
                                            placeholder={placeholderText}
                                            popoverProps={{
                                                className: 'ignore-react-onclickoutside',
                                            }}
                                            popperContentRef={userDropdownRef}
                                            filterable={false}
                                            showOnFocus={() => true}
                                            value={searchText}
                                            listItemProps={{
                                                itemWrapperProps: {
                                                    className: classnames(`${CSS_BLOCK}__user-item`),
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                )}
                                <ButtonToolbar className={classnames(`${CSS_BLOCK}__toolbar`)} muted>
                                    <ButtonToolbar.Center>
                                        <Button
                                            data-test="acting-as-session-cancel"
                                            onClick={onClose}
                                            size="md"
                                            type="link"
                                        >
                                            {formatter.formattedMessage({ id: 'CoreUI.cancel' })}
                                        </Button>
                                        <Button
                                            data-test="acting-as-session-start"
                                            disabled={disableStartSession}
                                            onClick={handleStartSession}
                                            size="md"
                                        >
                                            {formatter.formattedMessage({ id: 'CoreUI.startSession' })}
                                        </Button>
                                    </ButtonToolbar.Center>
                                </ButtonToolbar>
                            </>
                        )}
                    </Fieldset>
                </div>
            )}
            {isActingForOthers && (
                <div className={classnames(CSS_BLOCK, `${CSS_BLOCK}--toolbar`)}>
                    <ButtonToolbar muted>
                        <ButtonToolbar.Center>
                            <Button
                                data-test="acting-as-session-end"
                                onClick={handleEndSession}
                                size="md"
                            >
                                {formatter.formattedMessage({ id: 'CoreUI.endActingAsOther' })}
                            </Button>
                        </ButtonToolbar.Center>
                    </ButtonToolbar>
                </div>
            )}
        </>
    );
};

ActingAs.displayName = 'ActingAs';

ActingAs.propTypes = {
    isActingForOthers: PropTypes.bool,
    isGov: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.oneOf(getValues(ACTING_AS_OPTIONS)),
    ),
    onClose: PropTypes.func.isRequired,
    onEndSession: PropTypes.func,
    onEndLoading: PropTypes.func,
    onStartLoading: PropTypes.func,
    onStartSession: PropTypes.func,
    quickHelpRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    userDropdownRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

ActingAs.defaultProps = {
    options: [],
    onEndSession: () => {},
    onEndLoading: () => {},
    onStartSession: () => {},
    onStartLoading: () => {},
};

export default compose(
    withThemeStyles(styles),
    withLoggerData,
    withLogger,
    withFormatter,
)(ActingAs);
