import React from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import ButtonToolbar from '@concur/nui-widgets/lib/Buttons/ButtonToolbar';
import ControlLabel from '@concur/nui-widgets/lib/Forms/ControlLabel';
import MessageStrip from '@concur/nui-widgets/lib/MessageStrip/MessageStrip';
import TextArea from '@concur/nui-widgets/lib/Forms/TextArea';
import Title from '@concur/nui-widgets/lib/Title/Title';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose } from '@concur/core-ui-shell';
import { ASK_QUESTION_CONTACT_EMAIL, ASK_QUESTION_CONTACT_PHONE_NUMBER } from '../constants';
import * as styles from './AskQuestion-*.css';

const CSS_BLOCK = 'sapcnqr-ask-question';

const AskQuestion = (props) => {
    const {
        className,
        classNameMap,
        contactInfo,
        formatter,
        onClose,
        onSend,
        showError,
        showSuccess,
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const classes = classnames(CSS_BLOCK, className);

    if (showSuccess) {
        return (
            <div className={classes}>
                <MessageStrip
                    role="alert"
                    className={classnames(`${CSS_BLOCK}__message-strip`)}
                    message={(
                        <>
                            <Title headingLevel={2} headingStyle={3} wrap>
                                {formatter.formattedMessage({ id: 'CoreUI.hkTrialContactMeSuccessTitle' })}
                            </Title>
                            {formatter.formattedMessage({ id: 'CoreUI.hkTrialContactMeSuccessMsg' })}
                        </>

                    )}
                    type="success"
                    typeLabel="success"
                />
                <ButtonToolbar muted>
                    <ButtonToolbar.End>
                        <Button
                            onClick={onClose}
                            size="md"
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.close' })}
                        </Button>
                    </ButtonToolbar.End>
                </ButtonToolbar>
            </div>
        );
    }

    return (
        <div className={classes}>
            <form className={classnames(`${CSS_BLOCK}__form`)} method="post">
                <h2 className={classnames(`${CSS_BLOCK}__heading`)}>{formatter.formattedMessage({ id: 'CoreUI.hkTrialContactMeTitle' })}</h2>
                {showError && (
                    <MessageStrip
                        role="alert"
                        className={classnames(`${CSS_BLOCK}__message-strip`)}
                        message={(
                            <>
                                <Title headingLevel={2} headingStyle={3} wrap>
                                    {formatter.formattedMessage({ id: 'CoreUI.hkTrialContactMeErrorMsg' })}
                                </Title>
                                {formatter.formattedMessage({ id: 'CoreUI.hkTrialContactMeErrorDescription' })}
                                <ul className={classnames(`${CSS_BLOCK}__list`)}>
                                    <li className={classnames(`${CSS_BLOCK}__listitem`)}>
                                        <a
                                            className={classnames(`${CSS_BLOCK}__anchor`)}
                                            href={`mailto:${ASK_QUESTION_CONTACT_EMAIL}`}
                                        >
                                            {ASK_QUESTION_CONTACT_EMAIL}
                                        </a>
                                    </li>
                                    <li className={classnames(`${CSS_BLOCK}__listitem`)}>
                                        {ASK_QUESTION_CONTACT_PHONE_NUMBER}
                                    </li>
                                </ul>
                                {formatter.formattedMessage({ id: 'CoreUI.hkTrialContactMeErrorSignature' })}
                            </>
                        )}
                        type="error"
                        typeLabel="error"
                    />
                )}
                <div className={classnames(`${CSS_BLOCK}__sender`)}>
                    <div className={classnames(`${CSS_BLOCK}__label`)}>{formatter.formattedMessage({ id: 'CoreUI.hkTrialContactMeFromLabel' })}</div>
                    <div className={classnames(`${CSS_BLOCK}__contact-info`)}>
                        <div className={classnames(`${CSS_BLOCK}__contact-info-item`)}>{contactInfo.fullName}</div>
                        <div className={classnames(`${CSS_BLOCK}__contact-info-item`)}>{contactInfo.phoneNumber}</div>
                        <div className={classnames(`${CSS_BLOCK}__contact-info-item`)}>{contactInfo.loginName}</div>
                    </div>
                </div>
                <ControlLabel className={classnames(`${CSS_BLOCK}__control-label`)} htmlFor="hkTrial_contactTextArea">
                    {formatter.formattedMessage({ id: 'CoreUI.hkTrialContactMeIntroTxt' })}
                </ControlLabel>
                <div className={classnames(`${CSS_BLOCK}__instructions`)}>
                    {formatter.formattedMessage({ id: 'CoreUI.hkTrialContactMeIntroFootnote' })}
                </div>
                <input type="hidden" name="C_EmailAddress" value={contactInfo.loginName} />
                <input type="hidden" name="C_FirstName" value={contactInfo.firstName} />
                <input type="hidden" name="C_LastName" value={contactInfo.lastName} />
                <input type="hidden" name="C_BusPhone" value={contactInfo.phoneNumber} />
                <input type="hidden" name="elqFormName" value="Web_StandardTestDrive_Contact" />
                <input type="hidden" name="elqSiteID" value="537" />
                <TextArea
                    className={classnames(`${CSS_BLOCK}__textarea`)}
                    id="hkTrial_contactTextArea"
                    maxLength="2000"
                    name="C_Comments1"
                    size="md"
                />
                <ButtonToolbar muted>
                    <ButtonToolbar.End>
                        <Button
                            onClick={onClose}
                            size="md"
                            type="link"
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.close' })}
                        </Button>
                        <Button
                            onClick={onSend}
                            size="md"
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.send' })}
                        </Button>
                    </ButtonToolbar.End>
                </ButtonToolbar>
            </form>
        </div>
    );
};

AskQuestion.propTypes = {
    className: PropTypes.string,
    contactInfo: PropTypes.shape({
        fullName: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNumber: PropTypes.string,
        loginName: PropTypes.string,
    }),
    showError: PropTypes.bool,
    showSuccess: PropTypes.bool,
    onClose: PropTypes.func,
    onSend: PropTypes.func,
};

AskQuestion.defaultProps = {
    contactInfo: {},
    onClose: () => {},
    onSend: () => {},
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
)(AskQuestion);
