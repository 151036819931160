import classnamesBind from 'classnames/bind';
import Cookies from 'universal-cookie';
import { detect } from 'detect-browser';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Link from '@concur/nui-widgets/lib/Link/Link';
import MessageStrip from '@concur/nui-widgets/lib/MessageStrip/MessageStrip';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose, withErrorBoundary } from '@concur/core-ui-shell';
import { isFiori } from '@concur/ui-theming';
import reactToString from 'react-to-string';
import ScreenReaderMessageQueue from '../utils/ScreenReaderMessageQueue';
import SiteBanner from './_SiteBanner';
import { BANNER_TYPE, COOKIE_PREF_LEVEL } from '../constants';
import * as styles from './AppBanner-*.css';

const CSS_BLOCK = 'sapcnqr-app-banner';

const Banner = (props) => {
    const {
        brandingId,
        classNameMap,
        demo,
        formatter,
        themeName,
        userPrefs,
        useFullWidth,
        useSmallViewportBreakpoints,
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const [showLegacyBrowserWarning, setShowLegacyBrowserWarning] = useState(BANNER_TYPE.none);
    const [userCookiePref, setUserCookiePref] = useState(0);

    const fixedBannersRef = useRef();
    const testEntityBannerPlaceholder = useRef();
    const testEntityBannerContainer = useRef();
    const relativeBannersRef = useRef();

    const cookies = new Cookies();

    useEffect(() => {
        const browser = detect();

        const isIE = demo?.isIE ? true : browser?.name === 'ie';
        const isIE11 = isIE && parseInt(browser?.version, 10) === 11;

        // notice_preferences cookie: "0:", "1:", "2:"
        let noticePrefs = cookies.get('notice_preferences') ? cookies.get('notice_preferences').replace(/:/, '') : 0;
        noticePrefs = typeof demo?.cookieLevel === 'number' ? demo?.cookieLevel : parseInt(noticePrefs, 10);

        setUserCookiePref(noticePrefs);

        let isCookieSet = false;
        if (userCookiePref >= COOKIE_PREF_LEVEL.functional) {
            isCookieSet = cookies.get('hidebrowserwarning');
        }

        if (isIE && !isCookieSet) {
            setShowLegacyBrowserWarning(isIE11 ? BANNER_TYPE.ie11 : BANNER_TYPE.ieBefore11);
        }
    }, []);

    const isFioriTheme = () => isFiori(brandingId, themeName);

    const hasRelativeBanners = () => (showLegacyBrowserWarning || userPrefs?.isTestUser)
     && isFioriTheme();

    const setPlaceholderHeight = (placeholder, container) => {
        if (placeholder && container) {
            // eslint-disable-next-line no-param-reassign
            placeholder.style.height = `${container.offsetHeight}px`;
        }
    };

    // this header container and placeholder is drawn by the shell
    const getAncestralHeaderContainerEl = () => document?.getElementById('sapCnqrHeaderContainer');
    const getAncestralPlaceholderEl = () => document?.getElementById('sapCnqrHeaderPlaceholder');

    const updatePlaceholderHeights = () => {
        if (userPrefs?.isTestEntity) {
            setPlaceholderHeight(
                testEntityBannerPlaceholder?.current,
                testEntityBannerContainer?.current,
            );
        }
        setPlaceholderHeight(
            getAncestralPlaceholderEl(),
            getAncestralHeaderContainerEl(),
        );
    };

    const updateHeaderPosition = () => {
        updatePlaceholderHeights();
        const ancestralHeader = getAncestralHeaderContainerEl();
        if (ancestralHeader) {
            ancestralHeader.style.top = '0px';

            if (userPrefs?.isTestEntity && fixedBannersRef?.current) {
                ancestralHeader.style.top = `${fixedBannersRef.current.offsetHeight}px`;
            }
        }
    };

    const moveFixedBanner = () => {
        const ancestralHeader = getAncestralHeaderContainerEl();
        if (ancestralHeader && userPrefs?.isTestEntity && fixedBannersRef?.current) {
            const gramps = ancestralHeader.parentElement;
            gramps.insertBefore(fixedBannersRef.current, gramps.firstChild);
        }
        updateHeaderPosition();
    };

    const handleScroll = () => {
        const relativeBannersEl = relativeBannersRef?.current;
        const ancestralHeader = getAncestralHeaderContainerEl();
        if (hasRelativeBanners() && relativeBannersEl && ancestralHeader) {
            let scrollBuffer = 0;
            if (userPrefs?.isTestEntity && fixedBannersRef?.current) {
                scrollBuffer = fixedBannersRef.current.offsetHeight;
            }
            const windowScroll = window?.scrollY || window?.pageYOffset;
            if (windowScroll < relativeBannersEl.offsetHeight) {
                ancestralHeader.style.top = `${scrollBuffer - windowScroll}px`;
            } else {
                ancestralHeader.style.top = `${scrollBuffer - relativeBannersEl.offsetHeight}px`;
            }
        }
    };

    const handleLoad = () => {
        updatePlaceholderHeights();
        if (hasRelativeBanners()) {
            setTimeout(() => {
                moveFixedBanner();
            }, 0);
        }
    };

    const handleResize = () => {
        updatePlaceholderHeights();
        handleScroll();
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    });

    useEffect(() => {
        handleScroll();
        updateHeaderPosition();
        setTimeout(() => {
            moveFixedBanner();
        }, 0);
    });

    const onLegacyBannerClose = () => {
        if (userCookiePref >= COOKIE_PREF_LEVEL.functional) {
            const date = new Date();
            // set expiration date to two weeks
            date.setTime(date.getTime() + 14 * 24 * 60 * 60 * 1000);
            cookies.set('hidebrowserwarning', 'y', { path: '/', expires: date });
        }

        setShowLegacyBrowserWarning(BANNER_TYPE.none);
    };

    const hasFullWidthBanners = userPrefs?.isTestEntity || userPrefs?.isTestUser;

    const classes = classnames(
        CSS_BLOCK,
        {
            [`${CSS_BLOCK}--full-width`]: ((!isFioriTheme() || useFullWidth) && hasFullWidthBanners) || isFioriTheme(),
            [`${CSS_BLOCK}--fully-responsive`]: useSmallViewportBreakpoints,
        },
    );

    const dismissibleProps = userCookiePref >= COOKIE_PREF_LEVEL.functional ? {
        dismissible: {
            onDismiss: onLegacyBannerClose,
            dismissLabel: formatter.formattedMessage({ id: 'CoreUI.close' }),
        },
    } : {};

    const legacyWarning = showLegacyBrowserWarning === BANNER_TYPE.ieBefore11 ? formatter.formattedMessage({ id: 'CoreUI.legacyBrowserWarningDescription' })
        : (
            <>
                {formatter.formattedMessage({ id: 'CoreUI.ie11Warning' })}
                {' '}
                <Link
                    className={classnames(`${CSS_BLOCK}__legacy-warning-faq-link`)}
                    href="https://assets.concur.com/concurtraining/cte/en-us/FAQ_IE_11_Support_Policy_Change.pdf"
                >
                    {formatter.formattedMessage({ id: 'CoreUI.viewFAQ' })}
                </Link>
                <br />
                {formatter.formattedMessage({ id: 'CoreUI.CognosWarning' })}
                {' '}
                <Link
                    className={classnames(`${CSS_BLOCK}__legacy-warning-faq-link`)}
                    href="https://assets.concur.com/concurtraining/cte/en-us/FAQ_Cognos_11_UI_Update.pdf"
                >
                    {formatter.formattedMessage({ id: 'CoreUI.viewFAQ' })}
                </Link>
            </>
        );

    return (
        <>
            <div className={classes} ref={fixedBannersRef}>
                {
                    userPrefs?.isTestEntity
                    && (
                        <>
                            <SiteBanner
                                data-test="test-entity-site-banner"
                                accentColor={4}
                                iconName="gear"
                                ref={testEntityBannerContainer}
                                sticky
                                stickyFrame
                                text={formatter.formattedMessage({ id: 'CoreUI.testEntityBannerText' })}
                            />
                            <div
                                ref={testEntityBannerPlaceholder}
                                className={classnames(`${CSS_BLOCK}__test-entity-placeholder`)}
                            />
                        </>
                    )
                }
            </div>
            <div className={classes} ref={relativeBannersRef}>
                {userPrefs?.isTestUser
                    && (
                        <SiteBanner
                            data-test="test-user-site-banner"
                            accentColor={9}
                            iconName="user"
                            text={formatter.formattedMessage({ id: 'CoreUI.testUserBannerText' })}
                        />
                    )}
                {showLegacyBrowserWarning > BANNER_TYPE.none
                    && (
                        <>
                            <MessageStrip
                                className={classnames(`${CSS_BLOCK}__message-strip`)}
                                iconProps={{
                                    className: classnames(`${CSS_BLOCK}__legacy-warning-icon`),
                                }}
                                messageProps={{
                                    className: classnames(`${CSS_BLOCK}__legacy-warning-text`),
                                }}
                                message={legacyWarning}
                                type="error"
                                typeLabel={formatter.formattedMessage({ id: 'CoreUI.error.error' })}
                                {...dismissibleProps}
                            />
                            <ScreenReaderMessageQueue
                                announcement={reactToString(legacyWarning)}
                            />
                        </>
                    )}
            </div>
        </>
    );
};

Banner.displayName = 'Banner';

Banner.cssBlock = CSS_BLOCK;

Banner.propTypes = {
    demo: PropTypes.shape({
        isIE: PropTypes.bool,
        cookieLevel: PropTypes.number,
    }),
    userPrefs: PropTypes.object,
    useFullWidth: PropTypes.bool,
    useSmallViewportBreakpoints: PropTypes.bool,
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
    withErrorBoundary,
)(Banner);
